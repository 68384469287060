import React, { CSSProperties } from "react";
import "./Button.css";
import PropTypes from "prop-types";

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
};

interface defaultProps {
  className: string;
  label: string | React.ReactNode;
  disabled?: boolean;
  handleClick: () => void;
  style?: CSSProperties;
  id?: string;
}

function Button({
  className,
  label,
  disabled = false,
  handleClick,
  style,
  id,
  ...props
}: defaultProps) {
  return (
    <button
      id={id}
      style={style}
      onClick={handleClick}
      disabled={disabled}
      className={className}
      type="button"
      {...props}
    >
      {label}
    </button>
  );
}

export default Button;
