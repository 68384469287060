// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gen-spin {
    animation-name: gn-spinner;
    animation-delay: 0s;
    animation-direction: normal;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes gn-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/InlineSpinningLoader/InlineSpinningLoader.css"],"names":[],"mappings":"AAAA;IAEI,0BAA0B;IAE1B,mBAAmB;IAEnB,2BAA2B;IAE3B,sBAAsB;IAEtB,mCAAmC;IAEnC,iCAAiC;AACrC;;AAeA;IACI;QAEI,uBAAuB;IAC3B;;IAEA;QAEI,yBAAyB;IAC7B;AACJ","sourcesContent":[".gen-spin {\n    -webkit-animation-name: gn-spinner;\n    animation-name: gn-spinner;\n    -webkit-animation-delay: 0s;\n    animation-delay: 0s;\n    -webkit-animation-direction: normal;\n    animation-direction: normal;\n    -webkit-animation-duration: 2s;\n    animation-duration: 2s;\n    -webkit-animation-iteration-count: infinite;\n    animation-iteration-count: infinite;\n    -webkit-animation-timing-function: linear;\n    animation-timing-function: linear;\n}\n\n\n@-webkit-keyframes gn-spinner {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(360deg);\n        transform: rotate(360deg);\n    }\n}\n\n@keyframes gn-spinner {\n    0% {\n        -webkit-transform: rotate(0deg);\n        transform: rotate(0deg);\n    }\n\n    100% {\n        -webkit-transform: rotate(360deg);\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
