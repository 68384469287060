import React, { useEffect, useRef } from "react";
import "datatables.net";
// import 'datatables.net-dt/css/jquery.dataTables.css';
// import 'datatables.net-dt/css/dataTables.bootstrap.css';
// import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
// import 'datatables.net-fixedcolumns-dt/css/fixedColumns.dataTables.css';
// import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
const $ = require("jquery");

interface defaultProps {
  data: any;
  columns: any;
  className: string;
  search: string;
  tableName: string;
  order?: any;
  paging?: boolean;
  pagingType?: string;
  rowCallback?: any;
}

export function Table({ ...props }: defaultProps) {
  $.DataTable = require("datatables.net");
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: props.data,
      columns: props.columns,
      destroy: true, // I think some clean up is happening here
      searching: true,
      paging: props.paging,
      pagingType: props.pagingType,
      ordering: true,
      info: false,
      order: props.order,
      rowCallback: props.rowCallback,
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, []);

  const table = $(`#${props.tableName}`).DataTable();

  useEffect(() => {
    table.search(props.search).draw();
  }, [props.search]);

  useEffect(() => {
    table.clear();
    table.rows.add(props.data);
    table.draw();
  }, [props.data]);

  return (
    <table
      className={props.className}
      width="100%"
      id={props.tableName}
      ref={tableRef}
    ></table>
  );
}
