import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Dashboard from './pages/Dashboard/Dashboard'
import Icep from './pages/ICEP/Icep'
import Investigation from './pages/Investigation/Investigation'
import Login from './pages/Login/Login'
import Monitored from './pages/Monitored/Monitored'
import logo from './assets/logo.png'

function App() {
  return (
    <div className="app-background">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/investigation" element={<Investigation />} />
          <Route path="/icep" element={<Icep />} />
          <Route path="/monitored" element={<Monitored />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
