import React, { CSSProperties } from 'react'
import './Checkbox.css'
import PropTypes from 'prop-types'

Checkbox.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  style: PropTypes.element
}

interface defaultProps {
  imageChecked: string
  imageUnChecked: string
  hasChildren?: boolean
  checked: boolean
  handleChange: () => void
  onChangeDrop: () => void
  label?: string
  style?: CSSProperties
  checkboxStyle?: CSSProperties
  labelStyle?: CSSProperties
}

function Checkbox({
  imageChecked,
  imageUnChecked,
  checked,
  handleChange,
  label,
  style,
  checkboxStyle,
  labelStyle,
  hasChildren,
  onChangeDrop,
  ...props
}: defaultProps) {
  return (
    <div
      onClick={hasChildren ? () => onChangeDrop() : undefined}
      style={style}
      className="its-ui-checkbox-container">
      {checked ? (
        <img
          alt='checkbox'
          style={checkboxStyle}
          className="its-ui-checkbox"
          onClick={handleChange}
          src={imageChecked}
        />
      ) : (
          <img
          alt='checkbox'
          style={checkboxStyle}
          className="its-ui-checkbox"
          onClick={handleChange}
          src={imageUnChecked}
        />
      )}
      <label
        style={labelStyle}
        className={
          checked ? 'its-ui-checkbox-label-checked' : 'its-ui-checkbox-label'
        }>
        {label}
      </label>
    </div>
  )
}

export default Checkbox
