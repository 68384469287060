import React from "react";
import logo from "../../assets/logo.png";
import profile from "../../assets/profile.png";

const TopRow = () => {
  const [roles, setRoles] = React.useState<any[]>(
    JSON.parse(localStorage.getItem("roles") || "[]")
  );
  const [rolesOpen, setRolesOpen] = React.useState(false);

  return (
    <div className="top-row">
      <div className="logo-container">
        <img src={logo} className="logo" alt="logo" />
      </div>
      <div>
        <div
          className="profile-container"
          onClick={() => setRolesOpen(!rolesOpen)}
        >
          <img src={profile} className="profile" alt="profile" />
          <div className="profile-text">{rolesOpen ? ">" : "<"}</div>
        </div>
        {rolesOpen && (
          <div className="roles-container">
            {roles.map((role, index) => {
              return (
                <div
                  key={index}
                  className="role"
                  onClick={() => {
                    setRolesOpen(false);
                    if (role === "Admin") {
                      window.location.href = "/dashboard";
                    } else if (role === "Investigation") {
                      window.location.href = "/investigation";
                    } else {
                      window.location.href = "/icep";
                    }
                  }}
                >
                  {role === "Admin"
                    ? "Dashboard"
                    : role === "Investigation"
                    ? "Investigation User"
                    : "ICEP User"}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopRow;
