import React, { CSSProperties } from 'react'
import './Input.css'
import PropTypes from 'prop-types'
import Search from '../../assets/Search.svg'

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.element,
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  labelStyle: PropTypes.element,
  isSearch: PropTypes.bool,
}

interface defaultProps {
  isPassword?: boolean
  handlePassword?: () => void
  passwordImage?: string
  hasArrow?: boolean
  arrowImg?: string
  id: string
  arrowID?: string
  label?: string
  placeholder?: string
  value?: string
  defaultValue?: string
  handleChange: (val: string) => void
  handleClick?: () => void
  style?: CSSProperties
  inputStyle?: CSSProperties
  disabled?: boolean
  name: string
  type?: string
  step?: number
  handleBlur?: (val: string) => void
  isError?: boolean
  errorText?: string
  labelStyle?: CSSProperties
  hasChips?: boolean
  chipsId?: string
  min?: number
  max?: number
  inputClassName?: string
  isSearch?: boolean
  isSmall?: boolean
}

function Input({
  isPassword = false,
  handlePassword,
  passwordImage,
  label,
  id,
  placeholder,
  value,
  defaultValue,
  handleChange,
  handleClick,
  disabled,
  name,
  style,
  inputStyle = { width: '100%', backgroundColor: 'transparent' },
  type = 'text',
  step,
  handleBlur = () => null,
  isError = false,
  errorText,
  labelStyle = {},
  min,
  max,
  inputClassName = 'its-ui-input-disabled',
  isSearch = false,
  isSmall = false,
  ...props
}: defaultProps) {
  return (
    <div
      onClick={handleClick}
      className={
        
          disabled
          ? value === ''
            ? 'its-ui-input-control'
            : 'its-ui-input-control disabled'
          : 'its-ui-input-control'
      }
      style={
        {...style}
      }>
      <div className={isSmall ? 'its-ui-input-container-small' : 'its-ui-input-container'}>
        {isSearch && <img src={Search} alt="search" className="its-ui-input-search" onClick={() => {
          const input = document.getElementById(id) as HTMLInputElement
          input.focus()
        }}/>}

        <input
         autoComplete='off'
          className={
            disabled
              ? inputClassName
              : ''
          }
          style={inputStyle}
          // disabled={hasArrow && disabled}
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
          onBlur={(e) => handleBlur(e.target.value)}
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => handleChange(e.target.value)}
          step={step}
          min={min}
          max={max}
          {...props}
        />
        {/* <label style={labelStyle}>{label}</label> */}
      </div>
      {isError && (
        <div className="its-ui-error-container">
          <div className="its-ui-error-text">{errorText}</div>
        </div>
      )}
    </div>
  )
}

export default Input
