import React, { useEffect } from "react";

import "./Icep.css";
import Loading from "../../components/Loading/Loading";
import logo from "../../assets/logo.png";
import profile from "../../assets/profile.png";
import filter from "../../assets/filter.png";
import enlarge from "../../assets/enlarge.png";
import arrowDown from "../../assets/arrow-down.png";
import back from "../../assets/back.png";
import backwhite from "../../assets/backwhite.png";
import userprofile from "../../assets/userprofile.png";
import close from "../../assets/close.png";
import {
  GoogleMap,
  HeatmapLayer,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, set } from "date-fns";
import axios from "axios";
import base64 from "base-64";
import { Table } from "../../components/Table/Table";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {
  PieChart,
  Pie,
  Legend,
  Line,
  LineChart,
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  Label,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import TopRow from "../../components/TopRow/TopRow";
const $ = require("jquery");

const Icep: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [map, setMap] = React.useState<any>(null);
  const [mapCenter, setMapCenter] = React.useState<any>({
    lat: -29.857233,
    lng: 31.021517,
  });
  const { isLoaded } = useJsApiLoader({
    // id: 'google-map-script',
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    libraries: ["visualization"],
  });

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [areasOpen, setAreasOpen] = React.useState(false);
  const [statusesOpen, setStatusesOpen] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState("landing");
  const [area, setArea] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [areas, setAreas] = React.useState<any[]>([]);
  const [statuses, setStatuses] = React.useState<any[]>([]);
  const [vehicleData, setVehicleData] = React.useState<any[]>([]);
  const [searchVal, setSearchVal] = React.useState("");
  const [dealCode, setDealCode] = React.useState("005293:088191");
  const [images, setImages] = React.useState<any[]>([]);
  const [viewProofOfLife, setViewProofOfLife] = React.useState(false);
  const [viewingVehicle, setViewingVehicle] = React.useState(false);
  const [icepData, setIcepData] = React.useState<any[]>([]);
  const [icepDetails, setIcepDetails] = React.useState<any>([]);
  const [userInvestigationData, setUserInvestigationData] = React.useState<
    any[]
  >([]);
  const [viewingCommonalities, setViewingCommonalities] = React.useState(false);
  const [commonalitiesData, setCommonalitiesData] = React.useState<any>([]);
  const [top10Hotspot, setTop10Hotspot] = React.useState<any[]>([]);
  const [allHotspot, setAllHotspot] = React.useState<any[]>([]);
  const [top10HotspotExposure, setTop10HotspotExposure] = React.useState<any[]>(
    []
  );
  const [allHotspotExposure, setAllHotspotExposure] = React.useState<any[]>([]);
  const [rowHover, setRowHover] = React.useState(false);
  const [rowHoverData, setRowHoverData] = React.useState<any>(null);
  const [position, setPosition] = React.useState({ left: 0, top: 0 });

  const barColours = [
    "#DBE4FF",
    "#BFCEFF",
    "#9FB5FF",
    "#819FFF",
    "#5980FF",
    "#3766FF",
    "#164CFF",
    "#002FC9",
    "#002291",
    "#001351",
  ];

  // Update position on mousemove
  const handleMouseMove = (e: { pageX: any; pageY: any }) => {
    setPosition({
      left: e.pageX - 150,
      top: e.pageY - 150,
    });
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (!viewingVehicle) {
      getVehicleData();
    }
  }, [viewingVehicle, startDate, endDate, area, status]);

  useEffect(() => {
    if (viewingVehicle) {
      getIcepData();
      getVehicleImages();
      if (areas.length === 0) {
        setAreas(getAreaList());
      }
      if (statuses.length === 0) {
        setStatuses(getStatusList());
      }
    }
  }, [viewingVehicle, startDate, endDate, area, dealCode]);

  useEffect(() => {
    if (viewingCommonalities) {
      getCommonalitiesData();
    }
  }, [viewingCommonalities]);

  useEffect(() => {
    if (areas.length === 0) {
      setAreas(getAreaList());
    }
    if (statuses.length === 0) {
      setStatuses(getStatusList());
    }
  }, [vehicleData]);

  useEffect(() => {
    if (viewingCommonalities) {
      setTop10Hotspot(getTop10Hotspot());
      setAllHotspot(getAllHotspot());
      setTop10HotspotExposure(getTop10HotspotExposure());
      setAllHotspotExposure(getAllHotspotExposure());
    }
  }, [commonalitiesData]);

  function getVehicleData() {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/icep-landing-page",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            startdate:
              startDate !== null
                ? String(format(startDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            enddate:
              endDate !== null
                ? String(format(endDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            area: area !== "" ? area : null,
            // status: status !== '' ? status : null
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        const temp: any[] = [];
        response.data.data.transactions.forEach((transaction: any) => {
          const found = temp.find(
            (element) => element.vehicle_licence === transaction.vehicle_licence
          );
          if (found) {
            // found.risk_value += transaction.risk_value
          } else {
            temp.push(transaction);
          }
        });
        setVehicleData(temp);

        if (response.data.data.transactions.length > 0) {
          const gps1 = response.data.data.transactions[0].gps.split(",");
          setMapCenter({
            lat: Number(gps1[0]),
            lng: Number(gps1[1]),
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getIcepData() {
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/icep-collections-report",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            startdate:
              startDate !== null
                ? String(format(startDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            enddate:
              endDate !== null
                ? String(format(endDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            area: area !== "" ? area : null,
            deal_code: dealCode !== "" ? dealCode : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setIcepData(response.data.data.transactions);
        setUserInvestigationData(
          getUserInvestigationData(response.data.data.transactions)
        );
        setIcepDetails(response.data.data.details);

        if (response.data.data.transactions.length > 0) {
          const gps1 = response.data.data.transactions[0].gps.split(",");
          setMapCenter({
            lat: Number(gps1[0]),
            lng: Number(gps1[1]),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getVehicleImages() {
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/images",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          data: {
            deal_code: dealCode !== "" ? dealCode : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        if (response.data.data.images.length > 0) {
          setImages(response.data.data.images);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getUserInvestigationData(data: any[]) {
    const series = [
      {
        name: "Sunday",
        color: "#208DFC",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Monday",
        color: "#1022A2",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Tuesday",
        color: "#DC723F",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Wednesday",
        color: "#670979",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Thursday",
        color: "#E448A7",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Friday",
        color: "#734FBB",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Saturday",
        color: "#D9B231",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
    ];

    //get all transactions for each day of the week, then get the total value for each hour of the day and add it to the series data
    const sunday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 0;
    });
    const monday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 1;
    });
    const tuesday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 2;
    });
    const wednesday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 3;
    });
    const thursday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 4;
    });
    const friday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 5;
    });
    const saturday = data.filter((transaction) => {
      console.log(transaction);
      const date = new Date(transaction.datetime);
      return date.getDay() === 6;
    });

    sunday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[0].data[hour].value += 1;
    });

    monday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[1].data[hour].value += 1;
    });

    tuesday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[2].data[hour].value += 1;
    });

    wednesday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[3].data[hour].value += 1;
    });

    thursday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[4].data[hour].value += 1;
    });

    friday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[5].data[hour].value += 1;
    });

    saturday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[6].data[hour].value += 1;
    });

    console.log("series", series);

    return series;
  }

  function getCommonalitiesData() {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/icep-commonalities",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setCommonalitiesData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getTop10Hotspot() {
    const temp: any[] = [];
    commonalitiesData?.hotspot_areas?.forEach((area: any) => {
      const found = temp.find((element) => element.area === area.area);
      if (found) {
        found.arrears += area.arrears;
        found.value += area.value;
      } else {
        temp.push({
          area: area.area,
          arrears: area.arrears,
          value: area.value,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.forEach((area, index) => {
      area["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getAllHotspot() {
    const sortedHotspot = commonalitiesData?.hotspot_areas?.sort(
      (a: any, b: any) => Number(b.arrears) - Number(a.arrears)
    );
    if (sortedHotspot) {
      const tempHotspots: any[] = [];
      sortedHotspot.forEach((area: any) => {
        tempHotspots.push({
          area: area.area,
          value: area.value,
          arrears: area.arrears,
        });
      });

      return tempHotspots;
    } else {
      return [];
    }
  }

  function getTop10HotspotExposure() {
    const temp: any[] = [];
    commonalitiesData?.hotspot_areas_exposure?.forEach((area: any) => {
      const found = temp.find((element) => element.area === area.area);
      if (found) {
        found.arrears += area.arrears;
        found.value += area.value;
      } else {
        temp.push({
          area: area.area,
          arrears: area.arrears,
          value: area.value,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.forEach((area, index) => {
      area["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getAllHotspotExposure() {
    const sortedHotspot = commonalitiesData?.hotspot_areas_exposure?.sort(
      (a: any, b: any) => Number(b.arrears) - Number(a.arrears)
    );
    if (sortedHotspot) {
      const tempHotspots: any[] = [];
      sortedHotspot.forEach((area: any) => {
        tempHotspots.push({
          area: area.area,
          value: area.value,
          arrears: area.arrears,
        });
      });

      return tempHotspots;
    } else {
      return [];
    }
  }

  function getAreaList() {
    //extract all area values and remove duplicates and sort alphabetically from vehicleData
    const temp: React.SetStateAction<any[]> = [];
    if (!viewingVehicle) {
      vehicleData.forEach((transaction) => {
        temp.push(transaction.area);
      });
    } else {
      icepData.forEach((transaction) => {
        temp.push(transaction.area);
      });
    }

    const sortedAreas = temp.sort();

    const uniqueAreas: any[] = [];
    sortedAreas.forEach((area) => {
      if (!uniqueAreas.includes(area)) {
        uniqueAreas.push(area);
      }
    });

    const areaList: any[] = [];
    uniqueAreas.forEach((area) => {
      if (areaList.length === 0) {
        areaList.push({
          name: "All Areas",
          value: "",
        });
      } else {
        areaList.push({
          name: area,
          value: area,
        });
      }
    });

    return areaList;
  }

  function getStatusList() {
    //extract all status values and remove duplicates and sort alphabetically from vehicleData

    const temp: React.SetStateAction<any[]> = [];
    if (!viewingVehicle) {
      vehicleData.forEach((transaction) => {
        temp.push(transaction.status);
      });
    } else {
      icepData.forEach((transaction) => {
        temp.push(transaction.status);
      });
    }

    const sortedStatuses = temp.sort();

    const uniqueStatuses: any[] = [];
    sortedStatuses.forEach((status) => {
      if (!uniqueStatuses.includes(status)) {
        uniqueStatuses.push(status);
      }
    });

    const statusList: any[] = [];
    uniqueStatuses.forEach((status) => {
      statusList.push({
        name: status,
        value: status,
      });
    });

    return statusList;
  }

  const handleDateChange = (date: any) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  function enlargeMap() {
    return () => {
      const map = document.getElementById("map");
      const pageContent = document.getElementById("content");

      if (map) {
        map.classList.toggle("enlarged");
      }

      if (pageContent) {
        pageContent.classList.toggle("down");
      }
    };
  }

  const onLoad = (heatmap: { set: (arg0: string, arg1: string[]) => void }) => {
    // Access to the heatmap object
    console.log("load");

    heatmap.set("map", map);

    // You can customize the gradient here
    // heatmap.set('gradient', gradient)
  };

  const onUnmount = (heatmap: { set: (arg0: string, arg1: null) => void }) => {
    console.log("unmount");
    heatmap.set("data", null);
  };

  function getMake() {
    const make = Object.values(icepData ?? [])[0]?.make_model.split(" ");
    if (make && make[0] !== undefined && make[0] !== null) {
      return make[0];
    } else {
      return "";
    }
  }

  function getModel() {
    const model = Object.values(icepData ?? [])[0]?.make_model.split(" ");
    if (model && model[1] !== undefined && model[1] !== null) {
      return model[1];
    } else {
      return "";
    }
  }

  function getYear() {
    const year = Object.values(icepData ?? [])[0]?.make_model.split(" ");
    if (year && year[2] !== undefined && year[2] !== null) {
      return year[2];
    } else {
      return "";
    }
  }

  function onView(dealCode: string) {
    console.log(dealCode);
    setStartDate(null);
    setEndDate(null);
    // setArea('')
    // setStatus('')
    setDealCode(dealCode);
    setViewingVehicle(true);
  }

  (window as any).react_onView = onView;

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className="background">
      {loading && <Loading />}
      <div
        id="view-proof-of-life"
        className="modal-background"
        style={{
          display: viewProofOfLife ? "flex" : "none",
        }}
      >
        <div className="modal-container">
          <div className="modal-body">
            <div className="modal-images">
              <img
                src={images[0]?.url}
                className="modal-image-main"
                alt="vehicle"
                id="modal-image-main"
              />

              <div className="modal-small-images">
                {images.map((image, index) => {
                  return (
                    <div className="modal-image-container">
                      <img
                        key={index}
                        src={image.url}
                        className="modal-image"
                        alt="vehicle"
                        onClick={() => {
                          const mainImage = document.getElementById(
                            "modal-image-main"
                          ) as HTMLImageElement;
                          if (mainImage) {
                            console.log(image.url);
                            mainImage.setAttribute("src", image.url);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <Table
              order={
                searchVal === ""
                  ? [[1, "desc"]]
                  : [
                      [1, "desc"],
                      [0, "asc"],
                    ]
              }
              paging={false}
              tableName="transactions-icep-2"
              className="transactions-table2"
              search={searchVal}
              data={icepData}
              columns={[
                {
                  title: "Registration No.",
                  data: "vehicle_licence",
                },
                {
                  title: "Time",
                  data: "datetime",
                  render: (data: any) => {
                    return String(format(new Date(data), "HH:mm:ss"));
                  },
                },
                {
                  title: "Date",
                  data: "datetime",
                  render: (data: any) => {
                    return String(format(new Date(data), "dd.MM.yyy"));
                  },
                },
              ]}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                className="back-button-investigation"
                onClick={() => setViewProofOfLife(false)}
              >
                <span>
                  <img className="back" src={backwhite} alt="back" />
                </span>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="map" className="map-container">
        <GoogleMap
          onLoad={(map) => setMap(map)}
          mapContainerStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          center={mapCenter}
          zoom={12}
          options={{
            disableDefaultUI: true,
            backgroundColor: "white",
            maxZoom: 10,
          }}
        >
          {map && (
            <HeatmapLayer
              onLoad={onLoad}
              onUnmount={onUnmount}
              data={
                !viewingVehicle
                  ? vehicleData.map((transaction) => {
                      const gps = transaction.gps.split(",");
                      return {
                        location: new window.google.maps.LatLng(
                          Number(gps[0]),
                          Number(gps[1])
                        ),
                        weight: 1,
                      };
                    })
                  : icepData.map((transaction) => {
                      const gps = transaction.gps.split(",");
                      return {
                        location: new window.google.maps.LatLng(
                          Number(gps[0]),
                          Number(gps[1])
                        ),
                        weight: 1,
                      };
                    })
              }
              options={{
                dissipating: true,
                radius: 40,
                opacity: 0.6,
                maxIntensity: 10,
                // gradient: gradient,
                map: map,
              }}
            />
          )}
        </GoogleMap>
      </div>

      <TopRow />

      {!viewingVehicle && !viewingCommonalities && (
        <div id="content" className="content">
          <div className="second-row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="filter-container-icep"
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              >
                <img src={filter} className="filter" alt="filter" />
              </div>
              {filterOpen && (
                <div
                  style={{
                    zIndex: 100,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <DatePicker
                    selectsRange
                    selected={startDate}
                    onChange={(date) => handleDateChange(date)}
                    startDate={startDate !== null ? startDate : null}
                    endDate={endDate !== null ? endDate : null}
                    customInput={
                      <div className="filter-button">
                        {startDate !== null
                          ? String(format(startDate, "dd.MM.yyy"))
                          : "Start Date"}{" "}
                        -{" "}
                        {endDate !== null
                          ? String(format(endDate, "dd.MM.yyy"))
                          : "End Date"}
                        {startDate !== null && endDate !== null && (
                          <span
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setStartDate(null);
                              setEndDate(null);
                            }}
                          >
                            <img src={close} className="clear" alt="clear" />
                          </span>
                        )}
                      </div>
                    }
                  />

                  <div>
                    <div
                      className="filter-button"
                      onClick={() => setAreasOpen(!areasOpen)}
                    >
                      {area !== "" ? area : "All Areas"}
                      <span>
                        <img
                          className="arrow-down"
                          src={arrowDown}
                          alt="arrow-down"
                        />
                      </span>
                    </div>
                    {areasOpen && (
                      <div className="areas-container">
                        {areas.map((area, index) => {
                          return (
                            <div
                              key={index}
                              className="area"
                              onClick={() => {
                                setArea(area.value);
                                setAreasOpen(false);
                              }}
                            >
                              {area.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {/* <div>
                  <div className="filter-button" onClick={() => setStatusesOpen(!statusesOpen)}>
                    {status !== '' ? status : 'All Statuses'}
                    <span>
                      <img className="arrow-down" src={arrowDown} alt="arrow-down" />
                    </span>
                  </div>
                  {statusesOpen && (
                    <div className="areas-container">
                      {statuses.map((status, index) => {
                        return (
                          <div
                            key={index}
                            className="area"
                            onClick={() => {
                              setStatus(status.value)
                              setStatusesOpen(false)
                            }}>
                            {status.name}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div> */}
                </div>
              )}
            </div>
            <div className="tabs">
              <div
                className="tab-container"
                onClick={() => setViewingCommonalities(true)}
              >
                <div className={"tab-text"}>Commonalities</div>
              </div>
              <div className="enlarge-container" onClick={enlargeMap()}>
                <img src={enlarge} className="enlarge" alt="enlarge" />
              </div>
            </div>
          </div>
          <div className="table-container-icep">
            <div className="table-row-top-icep">
              <div className="table-header-icep">Vehicle Overview</div>
              <Input
                isSearch={true}
                style={{ margin: 0 }}
                label="Search"
                id="search-area"
                name="search"
                placeholder="Search..."
                value={searchVal}
                handleChange={(text) => {
                  setSearchVal(text);
                }}
                disabled={false}
                isError={false}
                errorText=""
                hasArrow={false}
              />
            </div>
            <Table
              rowCallback={function (row: any, data: any) {
                $(row).hover(function () {
                  setRowHover(true);
                  setRowHoverData(data);
                });

                $(row).mouseleave(function () {
                  setRowHover(false);
                  setRowHoverData(null);
                });
              }}
              order={[[3, "desc"]]}
              paging={true}
              pagingType="numbers"
              tableName="icep-vehicles"
              className="vehicle-data-table"
              search={searchVal}
              data={vehicleData}
              columns={[
                {
                  title: "Number",
                  data: "vehicle_licence",
                },
                {
                  title: "Vehicle Make",
                  data: "make_model",
                },
                {
                  title: "Deal Age",
                  data: "deal_age",
                },
                {
                  title: "Deal Risk Value",
                  data: "risk_value",
                  render: (data: any) => {
                    return "R" + data.toLocaleString();
                  },
                },
                {
                  title: "Deal Status",
                  data: "status",
                },
                {
                  title: "Owner",
                  data: "owner",
                },
                // {
                //   title: 'Owner Contact',
                //   data: 'dealer'
                // },
                {
                  title: "Area",
                  data: "area",
                },
                {
                  title: "Action",
                  data: "deal_code",
                  render: (data: any) => {
                    return `<div className="action-button" onClick="react_onView('${data}')">Select</div>`;
                  },
                },
              ]}
            />
            {rowHover && (
              <div
                className="hover-container"
                style={{
                  top: position.top + "px",
                  left: position.left + "px",
                }}
              >
                <div className="table-header">Owner Details</div>
                <div className="owner-details-icep">
                  <div className="owner-image-container-icep">
                    <img
                      src={userprofile}
                      className="owner-image"
                      alt="owner"
                    />
                  </div>
                  <div className="owner-info-icep">
                    <div className="owner-info-row-icep">
                      <div className="owner-info-header">Full Name:</div>
                      <div className="owner-info-data">
                        {rowHoverData?.owner}
                      </div>
                    </div>
                    <div className="owner-info-row-icep">
                      <div className="owner-info-header">Account No:</div>
                      <div className="owner-info-data">
                        {rowHoverData?.deal_code}
                      </div>
                    </div>
                    <div className="owner-info-row-icep">
                      <div className="owner-info-header">Age of Deal:</div>
                      <div className="owner-info-data">
                        {rowHoverData?.deal_age}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {viewingVehicle && (
        <div id="content" className="content">
          <div className="second-row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "30px",
              }}
            >
              <div
                style={{ marginLeft: 0 }}
                className="filter-button-investigation"
                onClick={() => setViewingVehicle(false)}
              >
                <span>
                  <img className="back" src={back} alt="back" />
                </span>
                Back
              </div>
              <div
                className="filter-container-investigation-2"
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              >
                <img src={filter} className="filter" alt="filter" />
              </div>
              {filterOpen && (
                <div
                  style={{
                    zIndex: 100,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <DatePicker
                    selectsRange
                    selected={startDate}
                    onChange={(date) => handleDateChange(date)}
                    startDate={startDate !== null ? startDate : null}
                    endDate={endDate !== null ? endDate : null}
                    customInput={
                      <div className="filter-button-investigation">
                        {startDate !== null
                          ? String(format(startDate, "dd.MM.yyy"))
                          : "Start Date"}{" "}
                        -{" "}
                        {endDate !== null
                          ? String(format(endDate, "dd.MM.yyy"))
                          : "End Date"}
                        {startDate !== null && endDate !== null && (
                          <span
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setStartDate(null);
                              setEndDate(null);
                            }}
                          >
                            <img src={close} className="clear" alt="clear" />
                          </span>
                        )}
                      </div>
                    }
                  />

                  <div>
                    <div
                      className="filter-button-investigation"
                      onClick={() => setAreasOpen(!areasOpen)}
                    >
                      {area !== "" ? area : "All Areas"}
                      <span>
                        <img
                          className="arrow-down"
                          src={arrowDown}
                          alt="arrow-down"
                        />
                      </span>
                    </div>
                    {areasOpen && (
                      <div className="areas-container">
                        {areas.map((area, index) => {
                          return (
                            <div
                              key={index}
                              className="area"
                              onClick={() => {
                                setArea(area.value);
                                setAreasOpen(false);
                              }}
                            >
                              {area.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {/* <div>
                    <div
                      className="filter-button-investigation"
                      onClick={() => setStatusesOpen(!statusesOpen)}>
                      {status !== '' ? status : 'All Statuses'}
                      <span>
                        <img className="arrow-down" src={arrowDown} alt="arrow-down" />
                      </span>
                    </div>
                    {statusesOpen && (
                      <div className="areas-container">
                        {statuses.map((status, index) => {
                          return (
                            <div
                              key={index}
                              className="area"
                              onClick={() => {
                                setStatus(status.value)
                                setStatusesOpen(false)
                              }}>
                              {status.name}
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div> */}
                </div>
              )}
            </div>
            <div className="tabs">
              <div className="enlarge-container" onClick={enlargeMap()}>
                <img src={enlarge} className="enlarge" alt="enlarge" />
              </div>
            </div>
          </div>
          <div className="icep-container">
            <div>
              <div className="owner-container">
                <div className="table-header">Owner Details</div>
                <div className="owner-details-icep">
                  <div className="owner-image-container-icep">
                    <img
                      src={userprofile}
                      className="owner-image"
                      alt="owner"
                    />
                  </div>
                  <div className="owner-info-icep">
                    <div className="owner-info-row-icep">
                      <div className="owner-info-header">Full Name:</div>
                      <div className="owner-info-data">
                        {Object.values(icepData ?? [])[0]?.owner}
                      </div>
                    </div>
                    <div className="owner-info-row-icep">
                      <div className="owner-info-header">Account No:</div>
                      <div className="owner-info-data">
                        {icepDetails?.deal_code}
                      </div>
                    </div>
                    <div className="owner-info-row-icep">
                      <div className="owner-info-header">Age of Deal:</div>
                      <div className="owner-info-data">
                        {icepDetails?.deal_age}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="user-investigation"
                className="user-investigation-container"
              >
                <div className="table-header">User Investigation</div>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <XAxis
                      allowDuplicatedCategory={false}
                      interval={2}
                      dataKey="tod"
                      axisLine={false}
                      tickLine={false}
                      style={{
                        textAnchor: "middle",
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#94A3B8",
                        lineHeight: "normal",
                        fontStyle: "normal",
                      }}
                    >
                      <Label
                        value="Time of day"
                        position="insideBottom"
                        style={{
                          textAnchor: "middle",
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "#C7C7C7",
                          lineHeight: "normal",
                          fontStyle: "normal",
                        }}
                      />
                    </XAxis>
                    <YAxis
                      type="number"
                      // tickCount={11}
                      dataKey="value"
                      axisLine={false}
                      tickLine={false}
                      style={{
                        textAnchor: "middle",
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#94A3B8",
                        lineHeight: "normal",
                        fontStyle: "normal",
                      }}
                    ></YAxis>
                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                    <Legend
                      verticalAlign="top"
                      iconType="circle"
                      iconSize={8}
                      formatter={(value, entry, index) => {
                        return (
                          <span
                            style={{
                              color: "#94A3B8",
                              fontFamily: "Poppins",
                              fontSize: "10px",
                              fontWeight: "400",
                              lineHeight: "normal",
                              fontStyle: "normal",
                            }}
                          >
                            {value.substring(0, 3)}
                          </span>
                        );
                      }}
                    />
                    {userInvestigationData.map((series) => (
                      <Line
                        dot={false}
                        dataKey="value"
                        data={series.data}
                        name={series.name}
                        key={series.name}
                        stroke={series.color}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <div className="collection-opportunities-container">
                <div className="table-row-top">
                  <div className="table-header">Collection Opportunity</div>
                  <Input
                    isSearch={true}
                    style={{ margin: 0 }}
                    label="Search"
                    id="search-area"
                    name="search"
                    placeholder="Serach..."
                    value={searchVal}
                    handleChange={(text) => {
                      setSearchVal(text);
                    }}
                    disabled={false}
                    isError={false}
                    errorText=""
                    hasArrow={false}
                  />
                </div>
                <Table
                  // order={[[2, 'desc']]}
                  paging={false}
                  tableName="collections-table"
                  className="collections-table"
                  search={searchVal}
                  data={icepData}
                  columns={[
                    {
                      title: "Camera ID",
                      data: "deviceid",
                    },
                    {
                      title: "Area",
                      data: "area",
                    },
                    {
                      title: "Date",
                      data: "datetime",
                      render: (data: any) => {
                        return String(
                          format(new Date(data), "HH:mm:ss dd.MM.yyy")
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", flex: 0.5 }}
            >
              <div className="vehicle-details-container-icep">
                <div className="table-header">Vehicle Details</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    // backgroundColor: 'blue',
                    marginTop: "10px",
                  }}
                >
                  <div className="vehicle-image-container-icep">
                    <img
                      src={Object.values(images ?? [])[0]?.url}
                      className="vehicle-image-icep"
                      alt="vehicle"
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      className="filter-button filter-button-blue-outline"
                      id="view-proof-button"
                      label="View Proof of Life"
                      handleClick={() => {
                        setViewProofOfLife(true);
                      }}
                    />
                  </div>
                  <div className="vehicle-info-icep">
                    <div className="vehicle-info-row-icep">
                      <div className="vehicle-info-header-top">
                        Registration No:
                      </div>
                      <div className="vehicle-info-data-top">
                        {icepDetails?.vehicle_licence}
                      </div>
                    </div>
                    <div className="vehicle-info-row-icep">
                      <div className="vehicle-info-header">Type:</div>
                      <div className="vehicle-info-data"></div>
                    </div>
                    <div className="vehicle-info-row-icep">
                      <div className="vehicle-info-header">Make:</div>
                      <div className="vehicle-info-data">{getMake()}</div>
                    </div>
                    <div className="vehicle-info-row-icep">
                      <div className="vehicle-info-header">Model:</div>
                      <div className="vehicle-info-data">{getModel()}</div>
                    </div>
                    <div className="vehicle-info-row-icep">
                      <div className="vehicle-info-header">Year:</div>
                      <div className="vehicle-info-data">{getYear()}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vehicle-details-container-icep">
                <div className="table-row-top">
                  <div className="table-header">Transactions</div>
                  <Input
                    isSmall={true}
                    isSearch={true}
                    style={{ margin: 0 }}
                    label="Search"
                    id="search-transactions"
                    name="search"
                    placeholder=""
                    value={searchVal}
                    handleChange={(text) => {
                      setSearchVal(text);
                    }}
                    disabled={false}
                    isError={false}
                    errorText=""
                    hasArrow={false}
                  />
                </div>
                <Table
                  paging={false}
                  tableName="transactions-icep"
                  className="transactions-table"
                  search={searchVal}
                  data={icepData}
                  columns={[
                    {
                      title: "Area",
                      data: "area",
                    },
                    {
                      title: "Time",
                      data: "datetime",
                      render: (data: any) => {
                        return String(format(new Date(data), "HH:mm:ss"));
                      },
                    },
                    {
                      title: "Date",
                      data: "datetime",
                      render: (data: any) => {
                        return String(format(new Date(data), "dd.MM.yyy"));
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {viewingCommonalities && (
        <div id="content" className="content">
          <div className="second-row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "30px",
              }}
            >
              <div
                style={{ marginLeft: 0 }}
                className="filter-button-investigation"
                onClick={() => setViewingCommonalities(false)}
              >
                <span>
                  <img className="back" src={back} alt="back" />
                </span>
                Back
              </div>
            </div>
            <div className="tabs">
              <div className="enlarge-container" onClick={enlargeMap()}>
                <img src={enlarge} className="enlarge" alt="enlarge" />
              </div>
            </div>
          </div>
          <div className="commonalities-container">
            <div className="table-container-hotspot">
              <div className="table-row-top-icep">
                <div className="table-header-icep">
                  Hotspot Areas: Frequencys
                </div>
              </div>
              <div className="pie-container">
                <PieChart
                  width={300}
                  height={300}
                  barGap={0}
                  barCategoryGap={0}
                >
                  <Pie data={top10Hotspot} dataKey="value" nameKey="area" />
                  <Tooltip
                    separator=""
                    cursor={false}
                    wrapperStyle={{
                      backgroundColor: "transparent",
                    }}
                    contentStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    label={""}
                    trigger="hover"
                    labelStyle={{ opacity: 0 }}
                    // active={tooltipOpen}
                    formatter={(value: any, name: any, props: any, id: any) => {
                      return (
                        <div className="tooltip-container">
                          <Table
                            paging={false}
                            search=""
                            tableName={"tooltip-table-area" + id}
                            className="count-table-tooltip"
                            columns={[
                              { title: "Area", data: "area" },
                              {
                                title: "Arrears",
                                data: "arrears",
                                render: (data: any) => {
                                  return "R" + data.toLocaleString();
                                },
                              },
                              {
                                title: "Count of ID",
                                data: "value",
                              },
                            ]}
                            data={[props.payload]}
                          />
                        </div>
                      );
                    }}
                  />
                </PieChart>
              </div>
              <Table
                search=""
                order={[[2, "desc"]]}
                paging={false}
                tableName="hotspot-areas"
                className="count-table-frequency"
                data={allHotspot}
                columns={[
                  {
                    title: "Area",
                    data: "area",
                  },
                  {
                    title: "Arrears",
                    data: "arrears",
                    render: (data: any) => {
                      return "R" + data.toLocaleString();
                    },
                  },
                  {
                    title: "Count of ID",
                    data: "value",
                  },
                ]}
              />
            </div>
            <div className="table-container-exposure">
              <div className="table-row-top-icep">
                <div className="table-header-icep">Hotspot Areas: Exposure</div>
              </div>
              <div className="pie-container">
                <PieChart
                  width={300}
                  height={300}
                  barGap={0}
                  barCategoryGap={0}
                >
                  <Pie
                    data={top10HotspotExposure}
                    dataKey="value"
                    nameKey="area"
                  />
                  <Tooltip
                    separator=""
                    cursor={false}
                    wrapperStyle={{
                      backgroundColor: "transparent",
                    }}
                    contentStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    label={""}
                    trigger="hover"
                    labelStyle={{ opacity: 0 }}
                    // active={tooltipOpen}
                    formatter={(value: any, name: any, props: any, id: any) => {
                      return (
                        <div className="tooltip-container">
                          <Table
                            paging={false}
                            search=""
                            tableName={"tooltip-table-area" + id + "exposure"}
                            className="count-table-tooltip"
                            columns={[
                              { title: "Area", data: "area" },
                              {
                                title: "Exposure",
                                data: "value",
                                render: (data: any) => {
                                  return data + "%";
                                },
                              },
                            ]}
                            data={[props.payload]}
                          />
                        </div>
                      );
                    }}
                  />
                </PieChart>
              </div>
              <Table
                search=""
                order={[[2, "desc"]]}
                paging={false}
                tableName="hotspot-areas-exposure"
                className="count-table-frequency"
                data={allHotspotExposure}
                columns={[
                  {
                    title: "Area",
                    data: "area",
                  },
                  {
                    title: "Arrears",
                    data: "arrears",
                    render: (data: any) => {
                      return "R" + data.toLocaleString();
                    },
                  },
                  {
                    title: "Exposure",
                    data: "value",
                    render: (data: any) => {
                      return data + "%";
                    },
                  },
                ]}
              />
            </div>

            <div className="table-container-commonalities">
              <div className="table-row-top-icep">
                <div className="table-header-icep">Commonalities</div>
                <Input
                  isSearch={true}
                  style={{ margin: 0 }}
                  label="Search"
                  id="search"
                  name="search"
                  placeholder="Search..."
                  value={searchVal}
                  handleChange={(text) => {
                    setSearchVal(text);
                  }}
                  disabled={false}
                  isError={false}
                  errorText=""
                />
              </div>
              <Table
                search=""
                order={[[2, "desc"]]}
                paging={false}
                tableName="commonalities"
                className="count-table-frequency"
                data={commonalitiesData?.commonalities}
                columns={[
                  {
                    title: "Area",
                    data: "area",
                  },
                  {
                    title: "Days",
                    data: "datetime",
                    render: (data: any) => {
                      return String(
                        format(new Date(data), "HH:mm:ss dd.MM.yyy")
                      );
                    },
                  },
                  {
                    title: "Exposure",
                    data: "arrears",
                    render: (data: any) => {
                      return "R" + data.toLocaleString();
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Icep;
