import './Loading.css'

interface Props  {
  style?: React.CSSProperties
}

export default function Loading({style} : Props) {
  return (
    <div className='loading' style={style}>
      <div className="loading-inner">
          <div className="arc"></div>
          <div className="arc"></div>
          <div className="arc"></div>
      </div>
    </div>
  )
}