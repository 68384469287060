import React, { useEffect } from "react";

import "./Investigation.css";
import Loading from "../../components/Loading/Loading";
import filter from "../../assets/filter.png";
import enlarge from "../../assets/enlarge.png";
import arrowDown from "../../assets/arrow-down.png";
import back from "../../assets/back.png";
import backwhite from "../../assets/backwhite.png";
import close from "../../assets/close.png";
import userprofile from "../../assets/userprofile.png";
import {
  GoogleMap,
  HeatmapLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, set } from "date-fns";
import axios from "axios";
import base64 from "base-64";
import { Table } from "../../components/Table/Table";
import Input from "../../components/Input/Input";
import Dropdown from "../../components/Dropdown/Dropdown";
import Button from "../../components/Button/Button";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import TopRow from "../../components/TopRow/TopRow";

const Investigation: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [map, setMap] = React.useState<any>(null);
  const [mapCenter, setMapCenter] = React.useState<any>({
    lat: -29.857233,
    lng: 31.021517,
  });
  const { isLoaded } = useJsApiLoader({
    // id: 'google-map-script',
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    libraries: ["visualization"],
  });

  const [startDateOpen, setStartDateOpen] = React.useState(false);
  const [endDateOpen, setEndDateOpen] = React.useState(false);
  const [areasOpen, setAreasOpen] = React.useState(false);
  const [areasOpen2, setAreasOpen2] = React.useState(false);
  const [statusesOpen, setStatusesOpen] = React.useState(false);
  const [statusesOpen2, setStatusesOpen2] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState("landing");
  const [area, setArea] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [areas, setAreas] = React.useState<any[]>([]);
  const [statuses, setStatuses] = React.useState<any[]>([]);
  const [vehicleData, setVehicleData] = React.useState<any[]>([]);
  const [investigationData, setInvestigationData] = React.useState<any[]>([]);
  const [userInvestigationData, setUserInvestigationData] = React.useState<
    any[]
  >([]);
  const [investigationDetails, setInvestigationDetails] = React.useState<any>(
    []
  );
  const [investigationIcepData, setInvestigationIcepData] = React.useState<
    any[]
  >([]);
  const [searchVal, setSearchVal] = React.useState("");
  const [resetClicked, setResetClicked] = React.useState(false);
  const [viewingVehicle, setViewingVehicle] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [dealCode, setDealCode] = React.useState("");
  const [images, setImages] = React.useState<any[]>([]);
  const [viewProofOfLife, setViewProofOfLife] = React.useState(false);
  const [icepUser, setIcepUser] = React.useState({
    id: "-------",
    name: "-------",
    mobile: "-------",
    email: "-------",
    region: "-------",
  });
  const [assignIcep, setAssignIcep] = React.useState(false);
  const [roles, setRoles] = React.useState<any[]>(
    JSON.parse(localStorage.getItem("roles") || "[]")
  );
  const [rolesOpen, setRolesOpen] = React.useState(false);

  const areaRef = React.useRef<HTMLDivElement>(null);
  const statusRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeDropdown = (e: any) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path.includes(areaRef.current)) {
        setAreasOpen(false);
      }

      if (!path.includes(statusRef.current)) {
        setStatusesOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  useEffect(() => {
    if (!viewingVehicle) {
      // getAreas()
      // getStatuses()
      getVehicleData();
    }
  }, []);

  useEffect(() => {
    if (resetClicked) {
      getVehicleData();
      setResetClicked(false);
    }
  }, [resetClicked]);

  useEffect(() => {
    if (viewingVehicle) {
      getInvestigationData();
      getVehicleImages();
      getIcepUsers();
      if (areas.length === 0) {
        setAreas(getAreaList());
      }
      if (statuses.length === 0) {
        setStatuses(getStatusList());
      }
    }
  }, [viewingVehicle, startDate, endDate, area, dealCode]);

  useEffect(() => {
    if (areas.length === 0) {
      setAreas(getAreaList());
    }
    if (statuses.length === 0) {
      setStatuses(getStatusList());
    }
  }, [vehicleData]);

  function getVehicleData() {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/user-landing-page",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            startdate:
              startDate !== null
                ? String(format(startDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            enddate:
              endDate !== null
                ? String(format(endDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            area: area !== "" ? area : null,
            status: status !== "" ? status : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        const temp: React.SetStateAction<any[]> = [];
        response.data.data.transactions.forEach((transaction: any) => {
          const found = temp.find(
            (item) => item.deal_code === transaction.deal_code
          );
          if (found) {
            found.value += Number(transaction.value);
            found.risk_value += Number(transaction.risk_value);
          } else {
            temp.push(transaction);
          }
        });

        if (response.data.data.transactions.length > 0) {
          const gps1 = response.data.data.transactions[0].gps.split(",");
          setMapCenter({
            lat: Number(gps1[0]),
            lng: Number(gps1[1]),
          });
          setVehicleData(temp);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getInvestigationData() {
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/proof-of-life-investigation",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            startdate:
              startDate !== null
                ? String(format(startDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            enddate:
              endDate !== null
                ? String(format(endDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            area: area !== "" ? area : null,
            deal_code: dealCode !== "" ? dealCode : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setInvestigationData(response.data.data.transactions);
        setUserInvestigationData(
          getUserInvestigationData(response.data.data.transactions)
        );
        setInvestigationDetails(response.data.data.details);

        setIcepUser({
          id: response.data.data.icep_details.id ?? "-------",
          name: response.data.data.icep_details.name ?? "-------",
          mobile: response.data.data.icep_details.phone ?? "-------",
          email: response.data.data.icep_details.email ?? "-------",
          region: response.data.data.icep_details.region ?? "-------",
        });

        if (response.data.data.transactions.length > 0) {
          const gps1 = response.data.data.transactions[0].gps.split(",");
          setMapCenter({
            lat: Number(gps1[0]),
            lng: Number(gps1[1]),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getVehicleImages() {
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/images",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          data: {
            deal_code: dealCode !== "" ? dealCode : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        if (response.data.data.images.length > 0) {
          setImages(response.data.data.images);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getIcepUsers() {
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/icep-users",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setInvestigationIcepData(response.data.data.details);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAreaList() {
    //extract all area values and remove duplicates and sort alphabetically from vehicleData
    const temp: React.SetStateAction<any[]> = [];
    if (!viewingVehicle) {
      vehicleData.forEach((transaction) => {
        temp.push(transaction.area);
      });
    } else {
      investigationData.forEach((transaction) => {
        temp.push(transaction.area);
      });
    }

    const sortedAreas = temp.sort();

    const uniqueAreas: any[] = [];
    sortedAreas.forEach((area) => {
      if (!uniqueAreas.includes(area)) {
        uniqueAreas.push(area);
      }
    });

    const areaList: any[] = [];
    uniqueAreas.forEach((area) => {
      if (area !== "") {
        areaList.push({
          name: area,
          value: area,
        });
      } else {
        areaList.push({
          name: "All Areas",
          value: "",
        });
      }
    });

    return areaList;
  }

  function getStatusList() {
    //extract all status values and remove duplicates and sort alphabetically from vehicleData and add 'All Statuses' to the top
    const temp: React.SetStateAction<any[]> = [];

    if (!viewingVehicle) {
      vehicleData.forEach((transaction) => {
        temp.push(transaction.status);
      });
    } else {
      investigationData.forEach((transaction) => {
        temp.push(transaction.status);
      });
    }

    const sortedStatuses = temp.sort();

    const uniqueStatuses: any[] = [];
    sortedStatuses.forEach((status) => {
      if (!uniqueStatuses.includes(status)) {
        uniqueStatuses.push(status);
      }
    });

    const statusList: any[] = [];
    uniqueStatuses.forEach((status) => {
      if (statusList.length === 0) {
        statusList.push({
          name: "All Statuses",
          value: "",
        });
      }
      statusList.push({
        name: status,
        value: status,
      });
    });

    return statusList;
  }

  function enlargeMap() {
    return () => {
      const map = document.getElementById("map");
      const pageContent = document.getElementById("content");

      if (map) {
        map.classList.toggle("enlarged");
      }

      if (pageContent) {
        pageContent.classList.toggle("down");
      }
    };
  }

  const onLoad = (heatmap: { set: (arg0: string, arg1: string[]) => void }) => {
    // Access to the heatmap object

    heatmap.set("map", map);

    // You can customize the gradient here
    // heatmap.set('gradient', gradient)
  };

  const onUnmount = (heatmap: { set: (arg0: string, arg1: null) => void }) => {
    heatmap.set("data", null);
  };

  function onView(dealCode: string) {
    console.log(dealCode);
    setStartDate(null);
    setEndDate(null);
    setArea("");
    setStatus("");
    setDealCode(dealCode);
    setViewingVehicle(true);
  }

  const handleDateChange = (date: any) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  function getMake() {
    const make = Object.values(investigationData ?? [])[0]?.make_model.split(
      " "
    );
    if (make && make[0] !== undefined && make[0] !== null) {
      return make[0];
    } else {
      return "";
    }
  }

  function getModel() {
    const model = Object.values(investigationData ?? [])[0]?.make_model.split(
      " "
    );
    if (model && model[1] !== undefined && model[1] !== null) {
      return model[1];
    } else {
      return "";
    }
  }

  function getYear() {
    const year = Object.values(investigationData ?? [])[0]?.make_model.split(
      " "
    );
    if (year && year[2] !== undefined && year[2] !== null) {
      return year[2];
    } else {
      return "";
    }
  }

  function onAssign(id: string) {
    const user = investigationIcepData.find((user) => user.id === id);
    if (user) {
      setIcepUser({
        id: user.id,
        name: user.name,
        mobile: user.phone,
        email: user.email,
        region: user.region,
      });
    }
    assignIcepUser(id);
    // setAssignIcep(false)
  }

  function assignIcepUser(id: string) {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/assign-icep",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          data: {
            deal_code: dealCode !== "" ? dealCode : null,
            icep_id: id,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setAssignIcep(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getUserInvestigationData(data: any[]) {
    const series = [
      {
        name: "Sunday",
        color: "#208DFC",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Monday",
        color: "#1022A2",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Tuesday",
        color: "#DC723F",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Wednesday",
        color: "#670979",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Thursday",
        color: "#E448A7",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Friday",
        color: "#734FBB",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
      {
        name: "Saturday",
        color: "#D9B231",
        data: [
          {
            tod: "00:00",
            value: 0,
          },
          {
            tod: "01:00",
            value: 0,
          },
          {
            tod: "02:00",
            value: 0,
          },
          {
            tod: "03:00",
            value: 0,
          },
          {
            tod: "04:00",
            value: 0,
          },
          {
            tod: "05:00",
            value: 0,
          },
          {
            tod: "06:00",
            value: 0,
          },
          {
            tod: "07:00",
            value: 0,
          },
          {
            tod: "08:00",
            value: 0,
          },
          {
            tod: "09:00",
            value: 0,
          },
          {
            tod: "10:00",
            value: 0,
          },
          {
            tod: "11:00",
            value: 0,
          },
          {
            tod: "12:00",
            value: 0,
          },
          {
            tod: "13:00",
            value: 0,
          },
          {
            tod: "14:00",
            value: 0,
          },
          {
            tod: "15:00",
            value: 0,
          },
          {
            tod: "16:00",
            value: 0,
          },
          {
            tod: "17:00",
            value: 0,
          },
          {
            tod: "18:00",
            value: 0,
          },
          {
            tod: "19:00",
            value: 0,
          },
          {
            tod: "20:00",
            value: 0,
          },
          {
            tod: "21:00",
            value: 0,
          },
          {
            tod: "22:00",
            value: 0,
          },
          {
            tod: "23:00",
            value: 0,
          },
        ],
      },
    ];

    //get all transactions for each day of the week, then get the total value for each hour of the day and add it to the series data
    const sunday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 0;
    });
    const monday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 1;
    });
    const tuesday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 2;
    });
    const wednesday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 3;
    });
    const thursday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 4;
    });
    const friday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 5;
    });
    const saturday = data.filter((transaction) => {
      const date = new Date(transaction.datetime);
      return date.getDay() === 6;
    });

    sunday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[0].data[hour].value += Number(transaction.value);
    });

    monday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[1].data[hour].value += Number(transaction.value);
    });

    tuesday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[2].data[hour].value += Number(transaction.value);
    });

    wednesday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[3].data[hour].value += Number(transaction.value);
    });

    thursday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[4].data[hour].value += Number(transaction.value);
    });

    friday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[5].data[hour].value += Number(transaction.value);
    });

    saturday.forEach((transaction) => {
      const hour = new Date(transaction.datetime).getHours();
      series[6].data[hour].value += Number(transaction.value);
    });

    return series;
  }

  (window as any).react_onView = onView;
  (window as any).react_onAssign = onAssign;

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className="background">
      {loading && <Loading />}
      <div
        id="view-proof-of-life"
        className="modal-background"
        style={{
          display: viewProofOfLife ? "flex" : "none",
        }}
      >
        <div className="modal-container">
          <div className="modal-body">
            <div className="modal-images">
              <img
                src={images[0]?.url}
                className="modal-image-main"
                alt="vehicle"
                id="modal-image-main"
              />

              <div className="modal-small-images">
                {images.map((image, index) => {
                  return (
                    <div className="modal-image-container">
                      <img
                        key={index}
                        src={image.url}
                        className="modal-image"
                        alt="vehicle"
                        onClick={() => {
                          const mainImage = document.getElementById(
                            "modal-image-main"
                          ) as HTMLImageElement;
                          if (mainImage) {
                            console.log(image.url);
                            mainImage.setAttribute("src", image.url);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <Table
              order={[[2, "asc"]]}
              paging={false}
              tableName="transactions2"
              className="transactions-table2"
              search={searchVal}
              data={investigationData}
              columns={[
                {
                  title: "Registration No.",
                  data: "vehicle_licence",
                },
                {
                  title: "Time",
                  data: "datetime",
                  render: (data: any) => {
                    return String(format(new Date(data), "HH:mm:ss"));
                  },
                },
                {
                  title: "Date",
                  data: "datetime",
                  render: (data: any) => {
                    return String(format(new Date(data), "dd.MM.yyy"));
                  },
                },
              ]}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                className="back-button-investigation"
                onClick={() => setViewProofOfLife(false)}
              >
                <span>
                  <img className="back" src={backwhite} alt="back" />
                </span>
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="view-proof-of-life"
        className="modal-background"
        style={{
          display: assignIcep ? "flex" : "none",
        }}
      >
        <div className="modal-container-large">
          <div className="modal-header">
            <div className="modal-header-title">Assign ICEP</div>
            <div
              className="modal-header-close"
              onClick={() => setAssignIcep(false)}
            >
              <span>
                <img className="close" src={close} alt="close" />
              </span>
            </div>
          </div>
          <div className="modal-body">
            <Table
              order={
                searchVal === ""
                  ? [[1, "desc"]]
                  : [
                      [1, "desc"],
                      [0, "asc"],
                    ]
              }
              paging={false}
              tableName="icep-users"
              className="icep-users-table"
              search={searchVal}
              data={investigationIcepData}
              columns={[
                {
                  title: "Name",
                  data: "name",
                },
                {
                  title: "Mobile",
                  data: "phone",
                },
                {
                  title: "Email",
                  data: "email",
                },
                {
                  title: "Region",
                  data: "region",
                },
                {
                  title: "ICEP Group",
                  data: "icep_group",
                },
                {
                  title: "Action",
                  data: "id",
                  render: (data: any) => {
                    return `<div className="action-button" onClick="react_onAssign(${data})">Select</div>`;
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div id="map" className="map-container">
        <GoogleMap
          onLoad={(map) => setMap(map)}
          mapContainerStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          center={mapCenter}
          zoom={12}
          options={{
            disableDefaultUI: true,
            backgroundColor: "white",
            maxZoom: 10,
          }}
        >
          {map && (
            <HeatmapLayer
              onLoad={onLoad}
              onUnmount={onUnmount}
              data={
                !viewingVehicle
                  ? vehicleData.map((transaction) => {
                      const gps = transaction.gps.split(",");
                      return {
                        location: new window.google.maps.LatLng(
                          Number(gps[0]),
                          Number(gps[1])
                        ),
                        weight: 1,
                      };
                    })
                  : investigationData.map((transaction) => {
                      const gps = transaction.gps.split(",");
                      return {
                        location: new window.google.maps.LatLng(
                          Number(gps[0]),
                          Number(gps[1])
                        ),
                        weight: 1,
                      };
                    })
              }
              options={{
                dissipating: true,
                radius: 40,
                opacity: 0.6,
                maxIntensity: 10,
                // gradient: gradient,
                map: map,
              }}
            />
          )}
        </GoogleMap>
      </div>

      <TopRow />

      {!viewingVehicle && (
        <div id="content" className="content">
          <div style={{ marginTop: "67px" }} className="second-row">
            <div className="filter-container-investigation">
              <div className="filters">
                <div style={{ backgroundColor: "transparent" }}>
                  <DatePicker
                    onInputClick={() => setStartDateOpen(!startDateOpen)}
                    onClickOutside={() => setStartDateOpen(false)}
                    value={
                      startDate !== null
                        ? String(format(startDate, "dd.MM.yyy"))
                        : ""
                    }
                    id="start-date-picker"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setStartDateOpen(false);
                    }}
                    open={startDateOpen}
                    customInput={
                      <Dropdown
                        id="start-date"
                        containerStyle={{}}
                        label="Start Date"
                        hasArrow={true}
                        isClearable={true}
                        onClear={() => {
                          setStartDate(null);
                          setStartDateOpen(false);
                        }}
                        arrowImg={arrowDown}
                        // value={startDate !== null ? String(format(startDate, 'dd.MM.yyy')) : ''}
                        handleClick={() => {
                          setStartDateOpen(!startDateOpen);
                        }}
                      />
                    }
                  />
                </div>
                <div style={{ backgroundColor: "transparent" }}>
                  <DatePicker
                    onClickOutside={() => setEndDateOpen(false)}
                    value={
                      endDate !== null
                        ? String(format(endDate, "dd.MM.yyy"))
                        : ""
                    }
                    id="end-date-picker"
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setEndDateOpen(false);
                    }}
                    open={endDateOpen}
                    customInput={
                      <Dropdown
                        isClearable={true}
                        onClear={() => {
                          setEndDate(null);
                          setEndDateOpen(false);
                        }}
                        id="end-date"
                        label="End Date"
                        hasArrow={true}
                        arrowImg={arrowDown}
                        value={
                          endDate !== null
                            ? String(format(endDate, "dd.MM.yyy"))
                            : ""
                        }
                        handleClick={() => {
                          setEndDateOpen(!endDateOpen);
                        }}
                      />
                    }
                  />
                </div>
                <div
                  className="area-status-container"
                  ref={areaRef}
                  style={{ backgroundColor: "transparent" }}
                >
                  <Dropdown
                    id="area"
                    label="Area"
                    hasArrow={true}
                    arrowImg={arrowDown}
                    value={area !== "" ? area : ""}
                    handleClick={() => {
                      setAreasOpen(!areasOpen);
                    }}
                  />
                  {areasOpen && (
                    <div
                      className="areas-container"
                      style={{
                        width: document.getElementById("area")?.clientWidth,
                      }}
                    >
                      {areas.map((area, index) => {
                        return (
                          <div
                            key={index}
                            className="area"
                            onClick={() => {
                              setArea(area.value);
                              setAreasOpen(false);
                            }}
                          >
                            {area.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div
                  className="area-status-container"
                  style={{ backgroundColor: "transparent" }}
                  ref={statusRef}
                >
                  <Dropdown
                    id="deal-status"
                    label="Deal Status"
                    containerStyle={{ flex: 1 }}
                    hasArrow={true}
                    arrowImg={arrowDown}
                    value={status !== "" ? status : ""}
                    handleClick={() => {
                      setStatusesOpen(!statusesOpen);
                    }}
                  />
                  {statusesOpen && (
                    <div
                      className="areas-container"
                      style={{
                        zIndex: 1000,
                        width:
                          document.getElementById("deal-status")?.clientWidth,
                      }}
                    >
                      {statuses.map((status, index) => {
                        return (
                          <div
                            style={{ zIndex: 1000 }}
                            key={index}
                            className="area"
                            onClick={() => {
                              setStatus(status.value);
                              setStatusesOpen(false);
                            }}
                          >
                            {status.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="filter-buttons">
                <Button
                  style={{ marginRight: "10px" }}
                  className="filter-button filter-button-blue"
                  id="filter-button"
                  label="Filter"
                  handleClick={() => {
                    getVehicleData();
                  }}
                />
                <Button
                  className="filter-button filter-button-white"
                  id="reset-button"
                  label="Reset"
                  handleClick={async () => {
                    await setStartDate(null);
                    await setEndDate(null);
                    await setArea("");
                    await setStatus("");
                    await setResetClicked(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table-container-icep">
            <div className="table-row-top-icep">
              <div className="table-header-icep">Vehicle Overview</div>
              <Input
                // labelStyle={{ marginLeft: '25px' }}
                isSearch={true}
                style={{ margin: 0 }}
                label="Search"
                id="search-area"
                name="search"
                placeholder="Search..."
                value={searchVal}
                handleChange={(text) => {
                  setSearchVal(text);
                }}
                disabled={false}
                isError={false}
                errorText=""
              />
            </div>
            <Table
              order={[[3, "desc"]]}
              paging={true}
              pagingType="numbers"
              tableName="overview-vehicles"
              className="vehicle-overview-table"
              search={searchVal}
              data={vehicleData}
              columns={[
                {
                  title: "Registration No.",
                  data: "vehicle_licence",
                },
                {
                  title: "Vehicle Make",
                  data: "make_model",
                },
                {
                  title: "Deal Age",
                  data: "deal_age",
                },
                {
                  title: "Deal Risk Value",
                  data: "risk_value",
                  render: (data: any) => {
                    return "R" + data.toLocaleString();
                  },
                },
                {
                  title: "Deal Status",
                  data: "status",
                },
                {
                  title: "Owner",
                  data: "owner",
                },
                // {
                //   title: 'Owner Contact',
                //   data: 'owner_contact'
                // },
                {
                  title: "Area",
                  data: "area",
                },
                {
                  title: "Hit Count",
                  data: "value",
                },
                {
                  title: "Action",
                  data: "deal_code",
                  render: (data: any) => {
                    return `<div className="action-button" onClick="react_onView('${data}')">View ></div>`;
                  },
                },
              ]}
            />
          </div>
        </div>
      )}
      {viewingVehicle && (
        <div id="content" className="content">
          <div className="second-row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "30px",
              }}
            >
              <div
                style={{ marginLeft: 0 }}
                className="filter-button-investigation"
                onClick={() => setViewingVehicle(false)}
              >
                <span>
                  <img className="back" src={back} alt="back" />
                </span>
                Back
              </div>
              <div
                className="filter-container-investigation-2"
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              >
                <img src={filter} className="filter" alt="filter" />
              </div>
              {filterOpen && (
                <div
                  style={{
                    zIndex: 100,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <DatePicker
                    selectsRange
                    selected={startDate}
                    onChange={(date) => handleDateChange(date)}
                    startDate={startDate !== null ? startDate : null}
                    endDate={endDate !== null ? endDate : null}
                    customInput={
                      <div className="filter-button-investigation">
                        {startDate !== null
                          ? String(format(startDate, "dd.MM.yyy"))
                          : "Start Date"}{" "}
                        -{" "}
                        {endDate !== null
                          ? String(format(endDate, "dd.MM.yyy"))
                          : "End Date"}
                        {startDate !== null && endDate !== null && (
                          <span
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setStartDate(null);
                              setEndDate(null);
                            }}
                          >
                            <img src={close} alt="close" />
                          </span>
                        )}
                      </div>
                    }
                  />

                  <div>
                    <div
                      className="filter-button-investigation"
                      onClick={() => setAreasOpen2(!areasOpen2)}
                    >
                      {area !== "" ? area : "All Areas"}
                      <span>
                        <img
                          className="arrow-down"
                          src={arrowDown}
                          alt="arrow-down"
                        />
                      </span>
                    </div>
                    {areasOpen2 && (
                      <div className="areas-container">
                        {areas.map((area, index) => {
                          return (
                            <div
                              key={index}
                              className="area"
                              onClick={() => {
                                setArea(area.value);
                                setAreasOpen2(false);
                              }}
                            >
                              {area.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div>
                    <div
                      className="filter-button-investigation"
                      onClick={() => setStatusesOpen2(!statusesOpen2)}
                    >
                      {status !== "" ? status : "All Statuses"}
                      <span>
                        <img
                          className="arrow-down"
                          src={arrowDown}
                          alt="arrow-down"
                        />
                      </span>
                    </div>
                    {statusesOpen2 && (
                      <div className="areas-container">
                        {statuses.map((status, index) => {
                          return (
                            <div
                              key={index}
                              className="area"
                              onClick={() => {
                                setStatus(status.value);
                                setStatusesOpen2(false);
                              }}
                            >
                              {status.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="tabs">
              <div className="enlarge-container" onClick={enlargeMap()}>
                <img src={enlarge} className="enlarge" alt="enlarge" />
              </div>
            </div>
          </div>
          <div className="investigation-container">
            <div>
              <div className="owner-container">
                <div className="table-header">Owner Details</div>
                <div className="owner-details">
                  <div className="owner-image-container">
                    <img
                      src={userprofile}
                      className="owner-image"
                      alt="owner"
                    />
                  </div>
                  <div className="owner-info">
                    <div className="owner-info-row">
                      <div className="owner-info-header">Full Name:</div>
                      <div className="owner-info-data">
                        {Object.values(investigationData ?? [])[0]?.owner}
                      </div>
                    </div>
                    <div className="owner-info-row">
                      <div className="owner-info-header">Account No:</div>
                      <div className="owner-info-data">
                        {investigationDetails?.deal_code}
                      </div>
                    </div>
                    <div className="owner-info-row">
                      <div className="owner-info-header">Age of Deal:</div>
                      <div className="owner-info-data">
                        {investigationDetails?.deal_age}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="user-investigation"
                className="user-investigation-container"
              >
                <div className="table-header">User Investigation</div>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <XAxis
                      allowDuplicatedCategory={false}
                      interval={2}
                      dataKey="tod"
                      axisLine={false}
                      tickLine={false}
                      style={{
                        textAnchor: "middle",
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#94A3B8",
                        lineHeight: "normal",
                        fontStyle: "normal",
                      }}
                    >
                      <Label
                        value="Time of day"
                        position="insideBottom"
                        style={{
                          textAnchor: "middle",
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "#C7C7C7",
                          lineHeight: "normal",
                          fontStyle: "normal",
                        }}
                      />
                    </XAxis>
                    <YAxis
                      type="number"
                      // tickCount={11}
                      dataKey="value"
                      axisLine={false}
                      tickLine={false}
                      style={{
                        textAnchor: "middle",
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "#94A3B8",
                        lineHeight: "normal",
                        fontStyle: "normal",
                      }}
                    >
                      <Label
                        value="Count"
                        position="center"
                        angle={-90}
                        style={{
                          textAnchor: "middle",
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "#C7C7C7",
                          lineHeight: "normal",
                          fontStyle: "normal",
                        }}
                      />
                    </YAxis>
                    <CartesianGrid strokeDasharray="0" horizontal={false} />
                    <Legend
                      verticalAlign="top"
                      iconType="circle"
                      iconSize={8}
                      formatter={(value, entry, index) => {
                        return (
                          <span
                            style={{
                              color: "#94A3B8",
                              fontFamily: "Poppins",
                              fontSize: "10px",
                              fontWeight: "400",
                              lineHeight: "normal",
                              fontStyle: "normal",
                            }}
                          >
                            {value.substring(0, 3)}
                          </span>
                        );
                      }}
                    />
                    {userInvestigationData.map((series) => (
                      <Line
                        dot={false}
                        dataKey="value"
                        data={series.data}
                        name={series.name}
                        key={series.name}
                        stroke={series.color}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <div className="vehicle-details-container">
                <div className="table-header">Vehicle Details</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <div className="vehicle-image-container">
                    <img
                      src={Object.values(images ?? [])[0]?.url}
                      className="vehicle-image"
                      alt="vehicle"
                    />
                  </div>
                  <div className="vehicle-info">
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header-top">
                        Registration No:
                      </div>
                      <div className="vehicle-info-data-top">
                        {investigationDetails?.vehicle_licence}
                      </div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Type:</div>
                      <div className="vehicle-info-data"></div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Make:</div>
                      <div className="vehicle-info-data">{getMake()}</div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Model:</div>
                      <div className="vehicle-info-data">{getModel()}</div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Year:</div>
                      <div className="vehicle-info-data">{getYear()}</div>
                    </div>
                  </div>
                  <div className="vehicle-info">
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header-top">
                        Deal Details:
                      </div>
                      <div className="vehicle-info-data-top"></div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Risk Value:</div>
                      <div className="vehicle-info-data">
                        R
                        {Object.values(
                          investigationData ?? []
                        )[0]?.risk_value.toLocaleString()}
                      </div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Warrant Status:</div>
                      <div className="vehicle-info-data">
                        {Object.values(investigationData ?? [])[0]?.status}
                      </div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Current Status:</div>
                      <div className="vehicle-info-data">
                        {investigationDetails?.account_status}
                      </div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Vehicle Status:</div>
                      <div className="vehicle-info-data"></div>
                    </div>
                  </div>
                  <div className="vehicle-info">
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header-top">
                        ICEP Selection:
                      </div>
                      <div
                        className="vehicle-info-data-blue"
                        onClick={() => setAssignIcep(true)}
                      >
                        {icepUser.email !== "-------" ? "Reassign" : "Assign"}
                      </div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Name:</div>
                      <div className="vehicle-info-data">{icepUser.name}</div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Mobile:</div>
                      <div className="vehicle-info-data">{icepUser.mobile}</div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Email:</div>
                      <div className="vehicle-info-data">{icepUser.email}</div>
                    </div>
                    <div className="vehicle-info-row">
                      <div className="vehicle-info-header">Region:</div>
                      <div className="vehicle-info-data">{icepUser.region}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flex: 1,
                    marginTop: "13px",
                  }}
                >
                  <Button
                    className="filter-button filter-button-blue-outline"
                    id="view-proof-button"
                    label="View Proof of Life"
                    handleClick={() => {
                      setViewProofOfLife(true);
                    }}
                  />
                </div>
              </div>
              <div className="vehicle-details-container">
                <div className="table-row-top">
                  <div className="table-header">Transactions</div>
                  <Input
                    // labelStyle={{ marginLeft: '25px' }}
                    isSearch={true}
                    style={{ margin: 0 }}
                    label="Search"
                    id="search-area"
                    name="search"
                    placeholder=""
                    value={searchVal}
                    handleChange={(text) => {
                      setSearchVal(text);
                    }}
                    disabled={false}
                    isError={false}
                    errorText=""
                    hasArrow={false}
                  />
                </div>
                <Table
                  order={[[2, "asc"]]}
                  paging={false}
                  tableName="transactions"
                  className="transactions-table"
                  search={searchVal}
                  data={investigationData}
                  columns={[
                    {
                      title: "Area",
                      data: "area",
                    },
                    {
                      title: "Time",
                      data: "datetime",
                      render: (data: any) => {
                        return String(format(new Date(data), "HH:mm:ss"));
                      },
                    },
                    {
                      title: "Date",
                      data: "datetime",
                      render: (data: any) => {
                        return String(format(new Date(data), "dd.MM.yyy"));
                      },
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Investigation;
