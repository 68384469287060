import React, { CSSProperties } from 'react'
import './Dropdown.css'
import PropTypes from 'prop-types'
import Search from '../../assets/Search.svg'
import close from '../../assets/close.png'

Dropdown.propTypes = {
  handleClick: PropTypes.func
}

interface defaultProps {
  handleClick?: () => void
  containerStyle?: CSSProperties
  inputStyle?: CSSProperties
  id?: string
  value?: string
  label?: string
  labelStyle?: CSSProperties
  hasArrow?: boolean
  arrowImg?: string
  type?: string
  isClearable?: boolean
  onClear?: () => void
}

function Dropdown({
  handleClick,
  containerStyle,
  inputStyle,
  id,
  value,
  label,
  labelStyle,
  hasArrow,
  arrowImg,
  type,
  isClearable,
  onClear,
  ...props
}: defaultProps) {
  return (
    <div onClick={handleClick} className="dropdown-control" style={containerStyle}>
      <div className="dropdown-container">
        <input
          type={type}
          // disabled
          autoComplete="off"
          className="dropdown-input"
          style={inputStyle}
          // disabled={hasArrow && disabled}
          id={id}
          value={value}
          // {...props}
        />
        <label className={'dropdown-label' + (value ? ' active' : '')} style={labelStyle}>
          {label}
        </label>

        {hasArrow && <img className="dropdown-arrow" src={arrowImg} alt="arrow" />}
        {isClearable && value && (
          <div
            style={{
              marginLeft: '5px'
            }}>
            <img onClick={onClear} className="dropdown-clear" src={close} alt="close" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropdown
