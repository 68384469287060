import React from "react";
import "./Login.css";
import LoginInput from "../../components/LoginInput/LoginInput";
import Button from "../../components/Button/Button";
import background from "../../assets/background.svg";
import CBVA from "../../assets/CBVA.png";
import Checkbox from "../../components/Checkbox/Checkbox";
import CheckboxTicked from "../../assets/CheckboxTicked.svg";
import CheckboxUnticked from "../../assets/CheckboxUnticked.svg";
import arrowdown from "../../assets/arrow-down.png";
import axios, { isCancel, AxiosError } from "axios";
import base64 from "base-64";
import Dropdown from "../../components/Dropdown/Dropdown";
import { el } from "date-fns/locale";
import { error } from "console";

const Login: React.FC = () => {
  const [isDev, setIsDev] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [rememberMe, setRememberMe] = React.useState<boolean>(false);
  const [userTypeDropdown, setUserTypeDropdown] =
    React.useState<boolean>(false);

  const logIn = () => {
    if (isDev) {
      window.location.href = "/dashboard";
    } else {
      fetch("https://dev.cbva.co.za/v1/user/login", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((data) => {
                if (!data.success) {
                  throw new Error(data.message);
                }
                const token = data.tokens.token;
                const refreshToken = data.tokens.refresh;
                const roles: string[] = [];
                data.roles.forEach((role: any) => {
                  roles.push(role.role);
                });

                localStorage.setItem("token", token);
                localStorage.setItem("refreshToken", refreshToken);
                localStorage.setItem("roles", JSON.stringify(roles));

                if (roles.includes("Admin")) {
                  window.location.href = "/dashboard";
                } else if (roles.includes("Investigation")) {
                  window.location.href = "/investigation";
                } else if (roles.includes("ICEP")) {
                  window.location.href = "/icep";
                }

                console.log("user", data.roles);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="login-background">
      <img src={background} alt="background" className="background-image" />
      <div className="login-container">
        <form id="formUser" className="login-form">
          <div className="form-header">
            <img src={CBVA} alt="CBVA" className="cbva-logo" />
            <div className="login-header-text">Sign In</div>
            <div className="login-subheader-text">
              Use your OneSpace Account
            </div>
          </div>
          <div className="form-content">
            <LoginInput
              style={{ width: "100%", margin: 0 }}
              label="Username"
              id="username"
              name="username"
              placeholder="Enter your username"
              value={username}
              handleChange={(text) => {
                setUsername(text);
              }}
              disabled={false}
              isError={false}
              errorText=""
            />
            <LoginInput
              style={{ width: "100%", margin: 0, marginTop: 10 }}
              label="Password"
              id="password"
              name="password"
              placeholder="Enter your password"
              value={password}
              handleChange={(text) => {
                setPassword(text);
              }}
              type="password"
              disabled={false}
              isError={false}
              errorText=""
            />
            <div className="forgot-password">Forgot password?</div>
            <Checkbox
              label="Remember me next time"
              checked={rememberMe}
              handleChange={() => setRememberMe(!rememberMe)}
              imageChecked={CheckboxTicked}
              imageUnChecked={CheckboxUnticked}
              onChangeDrop={() => {}}
              style={{ width: "100%", margin: 0, marginTop: 10 }}
              checkboxStyle={{ width: "20px", height: "20px" }}
            />
          </div>
          <div className="form-footer">
            <Button
              className="filter-button filter-button-blue"
              label="Sign In"
              disabled={false}
              handleClick={() => logIn()}
            />
            <div className="links-container">
              <div className="link-text">Confirm phone number</div>
              <div
                style={{
                  marginTop: "10px",
                }}
                className="link-text"
              >
                Didn't receive confirmation message?
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
                className="link-text"
              >
                Don't have an account? Sign up
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
