import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import profile from "../../assets/profile.png";
import filter from "../../assets/filter.png";
import enlarge from "../../assets/enlarge.png";
import arrowDown from "../../assets/arrow-down.png";
import { Table } from "../../components/Table/Table";
import "./Dashboard.css";
import base64 from "base-64";
import {
  PieChart,
  Pie,
  Legend,
  Line,
  LineChart,
  Bar,
  BarChart,
  Tooltip,
  Cell,
  AreaChart,
  Area,
  XAxis,
  Label,
  YAxis,
} from "recharts";
import Input from "../../components/Input/Input";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import { ResponsiveBar } from "@nivo/bar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, set } from "date-fns";
import {
  GoogleMap,
  HeatmapLayer,
  HeatmapLayerF,
  useJsApiLoader,
  OverlayView,
  LoadScript,
} from "@react-google-maps/api";
import TopRow from "../../components/TopRow/TopRow";

// const AnyReactComponent = ({ key }: any) => <div className="marker" />
const libraries: any[] = ["visualization"];

const Dashboard: React.FC = () => {
  // const options = {
  //   method: 'POST',
  //   headers: {
  //     // Authorization:
  //     //   'Basic ' + base64.encode('cbva' + ':' + '1e72ecb1-c0cd-4b4b-aeb7-32257997f549'),
  //     redirect: 'follow',
  //     mode: 'no-cors',
  //     'Content-type': 'application/json; charset=UTF-8'
  //   },
  //   data: [{
  //     'authentication': {
  //       'token': localStorage.getItem('token'),
  //       'refresh': localStorage.getItem('refreshToken')
  //     },
  //   }]

  // }
  // const {
  //   loading,
  //   error,
  //   data: exposure = []
  // } = useFetch('/v1/cbva/exposure-analysis-report', options, [
  // ])
  const { isLoaded } = useJsApiLoader({
    // id: 'google-map-script',
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    libraries: libraries,
  });
  const barChartAreaRef = React.useRef(null);
  const [map, setMap] = React.useState<any>(null);
  const [currentTab, setCurrentTab] = React.useState<string>("exposure");
  const [allocationByArea, setAllocationByArea] = React.useState<any[]>([]);
  const [top10AllocationByArea, setTop10AllocationByArea] = React.useState<
    any[]
  >([]);
  const [vehicleCountByWarrantStatus, setVehicleCountByWarrantStatus] =
    React.useState<any[]>([]);
  const [
    top10VehicleCountByWarrantStatus,
    setTop10VehicleCountByWarrantStatus,
  ] = React.useState<any[]>([]);
  const [vehicleCountByRecoveredStatus, setVehicleCountByRecoveredStatus] =
    React.useState<any[]>([]);
  const [
    top10VehicleCountByRecoveredStatus,
    setTop10VehicleCountByRecoveredStatus,
  ] = React.useState<any[]>([]);
  const [riskValue, setRiskValue] = React.useState<string>("R 0.00");
  const [vehicleRiskPool, setVehicleRiskPool] = React.useState<string>("0");
  const [highRiskReads, setHighRiskReads] = React.useState<string>("0");
  const [uniqueReads, setUniqueReads] = React.useState<string>("0");
  const [countByArea, setCountByArea] = React.useState<any[]>([]);
  const [top10Areas, setTop10Areas] = React.useState<any[]>([]);
  const [countByCamera, setCountByCamera] = React.useState<any[]>([]);
  const [top10Cameras, setTop10Cameras] = React.useState<any[]>([]);
  const [exposureRiskVehicles, setExposureRiskVehicles] = React.useState<any[]>(
    []
  );
  const [top10ExposureRiskVehicles, setTop10ExposureRiskVehicles] =
    React.useState<any[]>([]);
  const [allTransactions, setAllTransactions] = React.useState<any[]>([]);
  const [top10Transactions, setTop10Transactions] = React.useState<any[]>([]);
  const [currentWarrantStatus, setCurrentWarrantStatus] = React.useState<any[]>(
    []
  );
  const [top10CurrentWarrantStatus, setTop10CurrentWarrantStatus] =
    React.useState<any[]>([]);
  const [top10RecoveryStatus, setTop10RecoveryStatus] = React.useState<any[]>(
    []
  );
  const [warrantStatusTotal, setWarrantStatusTotal] = React.useState<any[]>([]);
  const [totalValueWarrantStatusTotal, setTotalValueWarrantStatusTotal] =
    React.useState<number>(0);
  const [totalValueWarrantStatus, setTotalValueWarrantStatus] =
    React.useState<number>(0);
  const [totalValueTransactions, setTotalValueTransactions] =
    React.useState<number>(0);
  const [totalValueRiskVehicles, setTotalValueRiskVehicles] = React.useState(0);
  const [totalArrears, setTotalArrears] = React.useState(0);
  const [totalValueCameras, setTotalValueCameras] = React.useState(0);
  const [totalArrearsCameras, setTotalArrearsCameras] = React.useState(0);
  const [totalValue, setTotalValue] = React.useState(0);
  const [totalValueStatus, setTotalValueStatus] = React.useState(0);
  const [totalValueVehicles, setTotalValueVehicles] = React.useState(0);
  const [searchVal, setSearchVal] = React.useState<string>("");
  const [searchValCameras, setSearchValCameras] = React.useState<string>("");
  const [searchValStatus, setSearchValStatus] = React.useState<string>("");
  const [searchValVehicle, setSearchValVehicle] = React.useState<string>("");
  const [riskValueData, setRiskValueData] = React.useState<any[]>([]);
  const [uniqueVehiclesByDate, setUniqueVehiclesByDate] = React.useState<any[]>(
    []
  );
  const [uniqueVehiclesByWarrantStatus, setUniqueVehiclesBYWarrantStatus] =
    React.useState<any[]>([]);
  const [currentStatus, setCurrentStatus] = React.useState<any[]>([]);
  const [top10Status, setTop10Status] = React.useState<any[]>([]);
  const [vehicleCount, setVehicleCount] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [exposure, setExposure] = React.useState<any>([]);
  const [warrant, setWarrant] = React.useState<any>([]);
  const [recovery, setRecovery] = React.useState<any>([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [area, setArea] = React.useState<string>("");
  const [status, setStatus] = React.useState<string>("");
  const [mapCenter, setMapCenter] = React.useState<any>({
    lat: -29.857233,
    lng: 31.021517,
  });
  const [transactions, setTransactions] = React.useState<any[]>([]);
  const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
  const [areasOpen, setAreasOpen] = React.useState<boolean>(false);
  const [areas, setAreas] = React.useState<any[]>([]);
  const [statuses, setStatuses] = React.useState<any[]>([]);
  // const [roles, setRoles] = React.useState<any[]>(
  //   JSON.parse(localStorage.getItem("roles") || "[]")
  // );

  // const colours = ['#4A6BDA', '#567AF3', '#6C8DF9', '#819EFA', '#9FB5FF']
  const barColours = [
    "#DBE4FF",
    "#BFCEFF",
    "#9FB5FF",
    "#819FFF",
    "#5980FF",
    "#3766FF",
    "#164CFF",
    "#002FC9",
    "#002291",
    "#001351",
  ];

  useEffect(() => {
    if (currentTab === "exposure") {
      getExposureData();
    } else if (currentTab === "warrant") {
      getWarrantData();
    } else {
      getRecoveryData();
    }
  }, [startDate, endDate, area, status, currentTab]);

  useEffect(() => {
    if (exposure?.data?.allocation_by_area) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      exposure?.data?.allocation_by_area.forEach((area: any, index: any) => {
        //group areas with same name
        const found = temp.find((element) => element.area === area.area);
        if (found) {
          found.value += Number(area.value);
        } else {
          temp.push({
            id: index,
            area: area.area,
            arrears: area.arrears,
            value: Number(area.value),
          });
        }

        totalValue += area.value;
      });

      const newtemp = temp.sort((a, b) => {
        return b.value - a.value;
      });

      newtemp.forEach((area: any) => {
        area["percentage"] = ((area.value / totalValue) * 100).toFixed(2);
      });

      setAllocationByArea(newtemp);

      setTop10AllocationByArea(getTop10AllocationByArea());
    }

    if (exposure?.data?.count_by_area) {
      const temp: React.SetStateAction<any[]> = [];
      let totalArrears = 0;
      let totalValue = 0;
      exposure?.data?.count_by_area.forEach((area: any, index: number) => {
        const found = temp.find((element) => element.area === area.area);
        if (found) {
          found.arrears += Number(area.arrears);
          found.value += Number(area.value);
        } else {
          temp.push({
            id: index,
            area: area.area,
            arrears: area.arrears,
            value: area.value,
          });
        }
        totalArrears += area.arrears;
        totalValue += area.value;
      });
      setTotalArrears(totalArrears);
      setTotalValue(totalValue);
      setCountByArea(temp);
      setTop10Areas(getTop10Areas());
    }

    if (exposure?.data?.count_by_count_by_camera) {
      const temp: React.SetStateAction<any[]> = [];
      let totalArrears = 0;
      let totalValue = 0;
      exposure?.data?.count_by_count_by_camera.forEach(
        (camera: any, index: number) => {
          const found = temp.find((element) => element.area === camera.area);
          if (found) {
            found.arrears += Number(camera.arrears);
            found.value += Number(camera.value);
          } else {
            temp.push({
              id: index,
              area: camera.area,
              arrears: camera.arrears,
              value: camera.value,
            });
          }
          totalArrears += camera.arrears;
          totalValue += camera.value;
        }
      );
      setTotalArrearsCameras(totalArrears);
      setTotalValueCameras(totalValue);
      setCountByCamera(temp);
      setTop10Cameras(getTop10Cameras());
    }

    if (exposure?.data?.current_status) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      exposure?.data?.current_status.forEach((status: any) => {
        const found = temp.find(
          (element) => element.current_status === status.current_status
        );
        if (found) {
          found.value += Number(status.value);
        } else {
          temp.push({
            current_status: status.current_status,
            area: status.area,
            value: status.value,
            date: status.date,
          });
        }
        totalValue += status.value;
      });
      setTotalValueStatus(totalValue);
      setCurrentStatus(temp);
      setTop10Status(getTop10Status());
    }

    if (exposure?.data?.risk_pool) {
      let totalRiskPool = 0;
      let totalHighRiskReads = 0;
      let totalUniqueReads = 0;
      const temp: React.SetStateAction<any[]> = [];
      exposure?.data?.risk_pool.vehicle_risk_pool.forEach((vehicle: any) => {
        totalRiskPool += vehicle.value;
      });
      exposure?.data?.risk_pool.high_risk_reads.forEach((vehicle: any) => {
        totalHighRiskReads += vehicle.value;
      });
      exposure?.data?.risk_pool.unique_vehicles_by_date.forEach(
        (vehicle: any) => {
          const found = temp.find(
            (element) => element.pv === format(vehicle.date, "yyyy-MM-dd")
          );
          if (found) {
            found.uv += Number(vehicle.value);
          } else {
            temp.push({
              uv: vehicle.value,
              pv: format(vehicle.date, "yyyy-MM-dd"),
              amt: vehicle.value,
            });
          }
          totalUniqueReads += vehicle.value;
        }
      );
      const newtemp = temp.sort((a, b) => {
        return new Date(b.pv).getTime() - new Date(a.pv).getTime();
      });

      const newTemp2 = newtemp.splice(0, 60);

      newTemp2.sort((a, b) => {
        return new Date(a.pv).getTime() - new Date(b.pv).getTime();
      });

      setVehicleRiskPool(totalRiskPool.toLocaleString());
      setHighRiskReads(totalHighRiskReads.toLocaleString());
      setUniqueReads(totalUniqueReads.toLocaleString());
      if (startDate !== null && endDate !== null) {
        setUniqueVehiclesByDate(newtemp);
      } else {
        setUniqueVehiclesByDate(newTemp2);
      }
    }

    if (exposure?.data?.risk_value) {
      let totalRiskValue = 0;
      const temp: React.SetStateAction<any[]> = [];
      exposure?.data?.risk_value.forEach((risk: any) => {
        temp.push({
          name: risk.area,
          uv: risk.value,
          pv: risk.date,
        });
      });

      temp.sort((a, b) => {
        return new Date(b.pv).getTime() - new Date(a.pv).getTime();
      });

      setRiskValue("R" + temp[0]?.uv?.toLocaleString());
      setRiskValueData(temp);
    }

    if (exposure?.data?.vehicle_count) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      exposure?.data?.vehicle_count.forEach((vehicle: any) => {
        const found = temp.find(
          (element) => element.vehiclelicence === vehicle.vehiclelicence
        );
        if (found) {
          found.value += Number(vehicle.value);
        } else {
          temp.push({
            vehiclelicence: vehicle.vehiclelicence,
            value: vehicle.value,
          });
        }
        totalValue += vehicle.value;
      });
      setVehicleCount(temp);
      setTotalValueVehicles(totalValue);
    }

    if (exposure?.data?.transactions) {
      const temp: React.SetStateAction<any[]> = [];

      exposure?.data?.transactions.forEach((transaction: any) => {
        temp.push({
          date: transaction.date,
          gps: transaction.gps,
          area: transaction.area,
          vehicle_licence: transaction.vehicle_licence,
        });
      });

      if (temp.length > 0) {
        const gps1 = temp[0].gps.split(",");
        setMapCenter({
          lat: Number(gps1[0]),
          lng: Number(gps1[1]),
        });
      }

      setTransactions(temp);
    }

    if (exposure?.data && areas.length === 0) {
      setAreas(getAreaList());
    }
  }, [exposure]);

  useEffect(() => {
    if (warrant?.data?.vehicle_count_by_warrant_status) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      warrant?.data?.vehicle_count_by_warrant_status.forEach((warrant: any) => {
        const found = temp.find(
          (element) => element.warrant_status === warrant.warrant_status
        );
        if (found) {
          found.value += Number(warrant.value);
        } else {
          temp.push({
            warrant_status: warrant.warrant_status,
            value: warrant.value,
          });
        }

        totalValue += warrant.value;
      });
      const newTemp = temp.sort((a, b) => {
        return b.value - a.value;
      });

      newTemp.forEach((warrant: any, index: number) => {
        warrant["percentage"] = ((warrant.value / totalValue) * 100).toFixed(2);
      });

      setVehicleCountByWarrantStatus(newTemp);
      setTop10VehicleCountByWarrantStatus(getTop10VehiclesByWarrantStatus());
    }

    if (warrant?.data?.risk_pool) {
      let totalRiskPool = 0;
      let totalHighRiskReads = 0;
      let totalUniqueReads = 0;
      const temp: React.SetStateAction<any[]> = [];
      warrant?.data?.risk_pool.vehicle_risk_pool.forEach((vehicle: any) => {
        totalRiskPool += vehicle.value;
      });
      warrant?.data?.risk_pool.high_risk_reads.forEach((vehicle: any) => {
        totalHighRiskReads += vehicle.value;
      });
      warrant?.data?.risk_pool.unique_vehicles_by_warrant_status.forEach(
        (vehicle: any) => {
          const found = temp.find(
            (element) => element.pv === format(vehicle.date, "yyyy-MM-dd")
          );
          if (found) {
            found.uv += Number(vehicle.value);
          } else {
            temp.push({
              uv: vehicle.value,
              pv: format(vehicle.date, "yyyy-MM-dd"),
              amt: vehicle.value,
            });
          }

          totalUniqueReads += vehicle.value;
        }
      );
      const newtemp = temp.sort((a, b) => {
        return new Date(b.pv).getTime() - new Date(a.pv).getTime();
      });

      const newTemp2 = newtemp.splice(0, 60);

      newTemp2.sort((a, b) => {
        return new Date(a.pv).getTime() - new Date(b.pv).getTime();
      });
      setVehicleRiskPool(totalRiskPool.toLocaleString());
      setHighRiskReads(totalHighRiskReads.toLocaleString());
      setUniqueReads(totalUniqueReads.toLocaleString());
      if (startDate !== null && endDate !== null) {
        setUniqueVehiclesBYWarrantStatus(newtemp);
      } else {
        setUniqueVehiclesBYWarrantStatus(newTemp2);
      }
    }

    if (warrant?.data?.risk_value) {
      let totalRiskValue = 0;
      const temp: React.SetStateAction<any[]> = [];
      warrant?.data?.risk_value.forEach((risk: any) => {
        temp.push({
          name: risk.area,
          uv: risk.value,
          pv: risk.value,
        });
        totalRiskValue += risk.value;
      });
      setRiskValue("R" + totalRiskValue.toLocaleString());
      setRiskValueData(temp);
    }

    if (warrant?.data?.vehicle_licence_by_warrant_status) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      warrant?.data?.vehicle_licence_by_warrant_status.forEach(
        (vehicle: any) => {
          const found = temp.find(
            (element) => element.vehicle_licence === vehicle.vehicle_licence
          );
          if (found) {
            found.value += Number(vehicle.value);
          } else {
            temp.push({
              account: vehicle.account,
              date: vehicle.date,
              status: vehicle.status,
              value: vehicle.value,
              vehicle_licence: vehicle.vehicle_licence,
            });
          }
          totalValue += vehicle.value;
        }
      );
      setTotalValueRiskVehicles(totalValue);
      setExposureRiskVehicles(temp);
      setTop10ExposureRiskVehicles(getTop10Vehicles());
    }

    if (warrant?.data?.transactions) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      warrant?.data?.transactions.forEach((transaction: any) => {
        const found = temp.find(
          (element) => element.vehicle_licence === transaction.vehicle_licence
        );
        if (found) {
          found.value += 1;
        } else {
          temp.push({
            date: transaction.date,
            gps: transaction.gps,
            status: transaction.status,
            vehicle_licence: transaction.vehicle_licence,
            value: 1,
          });
        }
        totalValue += 1;
      });

      if (temp.length > 0) {
        const gps1 = temp[0].gps.split(",");
        setMapCenter({
          lat: Number(gps1[0]),
          lng: Number(gps1[1]),
        });
      }

      setTotalValueTransactions(totalValue);
      setTransactions(temp);
      setAllTransactions(temp);
      setTop10Transactions(getTop10Transactions());
    }

    if (warrant?.data?.warrant_status_unique) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      warrant?.data?.warrant_status_unique.forEach((warrant: any) => {
        const found = temp.find((element) => element.status === warrant.status);
        if (found) {
          found.value += Number(warrant.value);
        } else {
          temp.push({
            status: warrant.status,
            value: warrant.value,
            date: warrant.date,
          });
        }
        totalValue += warrant.value;
      });
      setCurrentWarrantStatus(temp);
      setTotalValueWarrantStatus(totalValue);
      setTop10CurrentWarrantStatus(getTop10WarrantStatus());
    }

    if (warrant?.data?.warrant_status_total) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      warrant?.data?.warrant_status_total.forEach((warrant: any) => {
        const found = temp.find(
          (element) => element.vehiclelicence === warrant.vehiclelicence
        );
        if (found) {
          found.value += Number(warrant.value);
        } else {
          temp.push({
            vehiclelicence: warrant.vehiclelicence,
            value: warrant.value,
            date: warrant.date,
          });
        }
        totalValue += warrant.value;
      });

      setWarrantStatusTotal(temp);
      setTotalValueWarrantStatusTotal(totalValue);
    }

    if (warrant?.data && statuses.length === 0) {
      setStatuses(getStatusList());
    }
  }, [warrant]);

  useEffect(() => {
    if (recovery?.data?.vehicle_count_by_recovery_status) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      recovery?.data?.vehicle_count_by_recovery_status.forEach(
        (warrant: any) => {
          const found = temp.find(
            (element) => element.warrant_status === warrant.status
          );
          if (found) {
            found.value += Number(warrant.value);
          } else {
            temp.push({
              warrant_status: warrant.status,
              value: warrant.value,
              date: warrant.date,
            });
          }
          totalValue += warrant.value;
        }
      );

      const newTemp = temp.sort((a, b) => {
        return b.value - a.value;
      });

      newTemp.forEach((warrant: any, index: number) => {
        warrant["percentage"] = ((warrant.value / totalValue) * 100).toFixed(2);
      });

      setVehicleCountByRecoveredStatus(newTemp);
      setTop10VehicleCountByRecoveredStatus(
        getTop10VehiclesByRecoveredStatus()
      );
    }
    if (recovery?.data?.risk_value) {
      let totalRiskValue = 0;
      const temp: React.SetStateAction<any[]> = [];
      recovery?.data?.risk_value.forEach((risk: any) => {
        temp.push({
          name: risk.area,
          uv: risk.value,
          pv: risk.value,
        });
        totalRiskValue += risk.value;
      });
      setRiskValue("R" + totalRiskValue.toLocaleString());
      setRiskValueData(temp);
    }

    if (recovery?.data?.risk_pool) {
      let totalRiskPool = 0;
      let totalHighRiskReads = 0;
      let totalUniqueReads = 0;
      const temp: React.SetStateAction<any[]> = [];
      recovery?.data?.risk_pool.vehicle_risk_pool.forEach((vehicle: any) => {
        totalRiskPool += vehicle.value;
      });
      recovery?.data?.risk_pool.high_risk_reads.forEach((vehicle: any) => {
        totalHighRiskReads += vehicle.value;
      });
      recovery?.data?.risk_pool.unique_vehicles_by_recovery_status.forEach(
        (vehicle: any) => {
          const found = temp.find(
            (element) => element.pv === format(vehicle.date, "yyyy-MM-dd")
          );
          if (found) {
            found.uv += Number(vehicle.value);
          } else {
            temp.push({
              uv: vehicle.value,
              pv: format(vehicle.date, "yyyy-MM-dd"),
              amt: vehicle.value,
            });
          }

          totalUniqueReads += vehicle.value;
        }
      );
      const newtemp = temp.sort((a, b) => {
        return new Date(b.pv).getTime() - new Date(a.pv).getTime();
      });

      const newTemp2 = newtemp.splice(0, 60);

      newTemp2.sort((a, b) => {
        return new Date(a.pv).getTime() - new Date(b.pv).getTime();
      });

      setVehicleRiskPool(totalRiskPool.toLocaleString());
      setHighRiskReads(totalHighRiskReads.toLocaleString());
      setUniqueReads(totalUniqueReads.toLocaleString());
      setUniqueVehiclesBYWarrantStatus(newTemp2);
    }

    if (recovery?.data?.vehicle_licence_by_recovery_status) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      recovery?.data?.vehicle_licence_by_recovery_status.forEach(
        (vehicle: any) => {
          const found = temp.find(
            (element) => element.vehicle_licence === vehicle.vehicle_licence
          );
          if (found) {
            found.value += Number(vehicle.value);
          } else {
            temp.push({
              account: vehicle.account,
              date: vehicle.date,
              status: vehicle.status,
              value: vehicle.value,
              vehicle_licence: vehicle.vehicle_licence,
            });
          }
          totalValue += vehicle.value;
        }
      );

      setTotalValueRiskVehicles(totalValue);
      setExposureRiskVehicles(temp);
      setTop10ExposureRiskVehicles(getTop10VehiclesRecovery());
    }

    if (recovery?.data?.transactions) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      recovery?.data?.transactions.forEach((transaction: any) => {
        const found = temp.find(
          (element) => element.vehicle_licence === transaction.vehicle_licence
        );
        if (found) {
          found.value += 1;
        } else {
          temp.push({
            date: transaction.date,
            gps: transaction.gps,
            status: transaction.status,
            vehicle_licence: transaction.vehicle_licence,
            value: 1,
          });
        }
        totalValue += 1;
      });

      if (temp.length > 0) {
        const gps1 = temp[0].gps.split(",");
        setMapCenter({
          lat: Number(gps1[0]),
          lng: Number(gps1[1]),
        });
      }

      setTotalValueTransactions(totalValue);
      setTransactions(temp);
      setAllTransactions(temp);
      setTop10Transactions(getTop10TransactionsRecovery());
    }

    if (recovery?.data?.recovery_status_unique) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      recovery?.data?.recovery_status_unique.forEach((warrant: any) => {
        const found = temp.find((element) => element.status === warrant.status);
        if (found) {
          found.value += Number(warrant.value);
        } else {
          temp.push({
            status: warrant.status,
            value: warrant.value,
            date: warrant.date,
          });
        }
        totalValue += warrant.value;
      });
      setCurrentWarrantStatus(temp);
      setTotalValueWarrantStatus(totalValue);
    }

    if (recovery?.data?.recovery_status_total) {
      const temp: React.SetStateAction<any[]> = [];
      let totalValue = 0;
      recovery?.data?.recovery_status_total.forEach((warrant: any) => {
        const found = temp.find(
          (element) => element.vehicle_licence === warrant.vehiclelicence
        );
        if (found) {
          found.value += Number(warrant.value);
        } else {
          temp.push({
            vehicle_licence: warrant.vehiclelicence,
            value: warrant.value,
            date: warrant.date,
          });
        }
        totalValue += warrant.value;
      });
      const newTemp = temp.sort((a, b) => {
        return b.value - a.value;
      });
      setWarrantStatusTotal(newTemp);
      setTop10RecoveryStatus(getTop10RecoveryStatus());
      setTotalValueWarrantStatusTotal(totalValue);
    }

    if (recovery?.data && statuses.length === 0) {
      setStatuses(getStatusList());
    }
  }, [recovery]);

  // useEffect(() => {
  //   console.log("roles", roles);
  // }, [roles]);

  function resetAllData() {
    setAllocationByArea([]);
    setVehicleCountByWarrantStatus([]);
    setRiskValue("R 0.00");
    setVehicleRiskPool("0");
    setHighRiskReads("0");
    setUniqueReads("0");
    setCountByArea([]);
    setTop10Areas([]);
    setCountByCamera([]);
    setTop10Cameras([]);
    setExposureRiskVehicles([]);
    setTop10ExposureRiskVehicles([]);
    setAllTransactions([]);
    setTop10Transactions([]);
    setCurrentWarrantStatus([]);
    setTop10CurrentWarrantStatus([]);
    setWarrantStatusTotal([]);
    setTotalValueWarrantStatusTotal(0);
    setTotalValueWarrantStatus(0);
    setTotalValueTransactions(0);
    setTotalValueRiskVehicles(0);
    setTotalArrears(0);
    setTotalValueCameras(0);
    setTotalArrearsCameras(0);
    setTotalValue(0);
    setTotalValueStatus(0);
    setTotalValueVehicles(0);
    setSearchVal("");
    setSearchValCameras("");
    setSearchValStatus("");
    setSearchValVehicle("");
    setRiskValueData([]);
    setUniqueVehiclesByDate([]);
    setUniqueVehiclesBYWarrantStatus([]);
    setCurrentStatus([]);
    setTop10Status([]);
    setVehicleCount([]);
    setTransactions([]);
    setTop10RecoveryStatus([]);
  }

  function getExposureData() {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/exposure-analysis-report",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            startdate:
              startDate !== null
                ? String(format(startDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            enddate:
              endDate !== null
                ? String(format(endDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            area: area !== "" ? area : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setExposure(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getWarrantData() {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/warrant-analysis-report",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            startdate:
              startDate !== null
                ? String(format(startDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            enddate:
              endDate !== null
                ? String(format(endDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            status: status !== "" ? status : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setWarrant(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getRecoveryData() {
    setLoading(true);
    axios
      .post(
        "https://dev.cbva.co.za/v1/cbva/recovery-analysis-report",
        {
          authentication: {
            token: localStorage.getItem("token"),
            refresh: localStorage.getItem("refreshToken"),
          },
          filters: {
            startdate:
              startDate !== null
                ? String(format(startDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            enddate:
              endDate !== null
                ? String(format(endDate, "yyy-MM-dd")) + " 00:00:00"
                : null,
            status: status !== "" ? status : null,
          },
        },
        {
          headers: {
            Authorization:
              "Basic " +
              base64.encode("cbva: 1e72ecb1-c0cd-4b4b-aeb7-32257997f549"),
          },
        }
      )
      .then((response) => {
        setRecovery(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getTop10AllocationByArea() {
    const temp: React.SetStateAction<any[]> = [];
    exposure?.data?.allocation_by_area.forEach((area: any, index: number) => {
      const found = temp.find((element) => element.area === area.area);
      if (found) {
        found.value += Number(area.value);
      } else {
        temp.push({
          id: index,
          area: area.area,
          arrears: area.arrears,
          value: area.value,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.forEach((area: any, index: any) => {
      area["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getTop10Areas() {
    const temp: React.SetStateAction<any[]> = [];
    exposure?.data?.count_by_area.forEach((area: any, index: number) => {
      const found = temp.find((element) => element.area === area.area);
      if (found) {
        found.arrears += Number(area.arrears);
        found.value += Number(area.value);
      } else {
        temp.push({
          id: index,
          area: area.area,
          arrears: area.arrears,
          value: area.value,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);
    newTemp.sort((a, b) => {
      return a.value - b.value;
    });

    return newTemp;
  }

  function getTop10Cameras() {
    const temp: React.SetStateAction<any[]> = [];
    exposure?.data?.count_by_count_by_camera.forEach(
      (camera: any, index: number) => {
        const found = temp.find((element) => element.area === camera.area);
        if (found) {
          found.arrears += Number(camera.arrears);
          found.value += Number(camera.value);
        } else {
          temp.push({
            id: index,
            area: camera.area,
            arrears: camera.arrears,
            value: camera.value,
          });
        }
      }
    );

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);
    newTemp.sort((a, b) => {
      return a.value - b.value;
    });

    return newTemp;
  }

  function getTop10Status() {
    const temp: React.SetStateAction<any[]> = [];
    exposure?.data?.current_status.forEach((status: any) => {
      const found = temp.find(
        (element) => element.current_status === status.current_status
      );
      if (found) {
        found.value += Number(status.value);
      } else {
        temp.push({
          current_status: status.current_status,
          area: status.area,
          value: status.value,
          date: status.date,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);
    newTemp.forEach((status: any, index: any) => {
      status["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getTop10VehiclesByWarrantStatus() {
    const temp: React.SetStateAction<any[]> = [];
    warrant?.data?.vehicle_count_by_warrant_status.forEach((warrant: any) => {
      const found = temp.find(
        (element) => element.warrant_status === warrant.warrant_status
      );
      if (found) {
        found.value += Number(warrant.value);
      } else {
        temp.push({
          warrant_status: warrant.warrant_status,
          value: warrant.value,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.forEach((warrant: any, index: any) => {
      warrant["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getTop10VehiclesByRecoveredStatus() {
    const temp: React.SetStateAction<any[]> = [];
    recovery?.data?.vehicle_count_by_recovery_status.forEach((warrant: any) => {
      const found = temp.find(
        (element) => element.warrant_status === warrant.status
      );
      if (found) {
        found.value += Number(warrant.value);
      } else {
        temp.push({
          warrant_status: warrant.status,
          value: warrant.value,
          date: warrant.date,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.forEach((warrant: any, index: any) => {
      warrant["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getTop10Vehicles() {
    const temp: React.SetStateAction<any[]> = [];
    warrant?.data?.vehicle_licence_by_warrant_status.forEach((vehicle: any) => {
      const found = temp.find(
        (element) => element.vehicle_licence === vehicle.vehicle_licence
      );
      if (found) {
        found.value += Number(vehicle.value);
      } else {
        temp.push({
          account: vehicle.account,
          date: vehicle.date,
          status: vehicle.status,
          value: vehicle.value,
          vehicle_licence: vehicle.vehicle_licence,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.sort((a, b) => {
      return a.value - b.value;
    });

    return newTemp;
  }

  function getTop10VehiclesRecovery() {
    const temp: React.SetStateAction<any[]> = [];
    recovery?.data?.vehicle_licence_by_recovery_status.forEach(
      (vehicle: any) => {
        const found = temp.find(
          (element) => element.vehicle_licence === vehicle.vehicle_licence
        );
        if (found) {
          found.value += Number(vehicle.value);
        } else {
          temp.push({
            account: vehicle.account,
            date: vehicle.date,
            status: vehicle.status,
            value: vehicle.value,
            vehicle_licence: vehicle.vehicle_licence,
          });
        }
      }
    );

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.sort((a, b) => {
      return a.value - b.value;
    });

    return newTemp;
  }

  function getTop10Transactions() {
    const temp: React.SetStateAction<any[]> = [];
    warrant?.data?.transactions.forEach((transaction: any, index: any) => {
      const found = temp.find(
        (element) => element.vehicle_licence === transaction.vehicle_licence
      );
      if (found) {
        found.value += 1;
      } else {
        temp.push({
          id: index,
          date: transaction.date,
          gps: transaction.gps,
          status: transaction.status,
          vehicle_licence: transaction.vehicle_licence,
          value: 1,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.forEach((warrant: any, index: any) => {
      warrant["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getTop10TransactionsRecovery() {
    const temp: React.SetStateAction<any[]> = [];
    recovery?.data?.transactions.forEach((transaction: any, index: any) => {
      const found = temp.find(
        (element) => element.vehicle_licence === transaction.vehicle_licence
      );
      if (found) {
        found.value += 1;
      } else {
        temp.push({
          id: index,
          date: transaction.date,
          gps: transaction.gps,
          status: transaction.status,
          vehicle_licence: transaction.vehicle_licence,
          value: 1,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);

    newTemp.forEach((warrant: any, index: any) => {
      warrant["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getAllWarrantStatus() {
    let sortedStatus;
    if (currentTab === "warrant") {
      sortedStatus = warrant?.data?.warrant_status_unique.sort(
        (a: any, b: any) => Number(b.value) - Number(a.value)
      );
    } else {
      sortedStatus = recovery?.data?.recovery_status_unique.sort(
        (a: any, b: any) => Number(b.value) - Number(a.value)
      );
    }

    let totalValue = 0;
    const temp: React.SetStateAction<any[]> = [];
    sortedStatus.forEach((status: any) => {
      temp.push({
        status: status.status,
        value: status.value,
      });
      totalValue += status.value;
    });

    setTotalValueWarrantStatus(totalValue);

    return temp;
  }

  function getTop10WarrantStatus() {
    const temp: React.SetStateAction<any[]> = [];
    warrant?.data?.warrant_status_unique.forEach((warrant: any) => {
      const found = temp.find((element) => element.status === warrant.status);
      if (found) {
        found.value += Number(warrant.value);
      } else {
        temp.push({
          status: warrant.status,
          value: warrant.value,
          date: warrant.date,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);
    newTemp.forEach((status: any, index: any) => {
      status["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getTop10RecoveryStatus() {
    const temp: React.SetStateAction<any[]> = [];
    recovery?.data?.recovery_status_total.forEach((warrant: any) => {
      const found = temp.find(
        (element) => element.vehicle_licence === warrant.vehiclelicence
      );
      if (found) {
        found.value += Number(warrant.value);
      } else {
        temp.push({
          vehicle_licence: warrant.vehiclelicence,
          value: warrant.value,
          date: warrant.date,
        });
      }
    });

    temp.sort((a, b) => {
      return b.value - a.value;
    });

    const newTemp = temp.splice(0, 10);
    newTemp.forEach((status: any, index: any) => {
      status["fill"] = barColours[index];
    });

    return newTemp;
  }

  function getAreaList() {
    //extract all area values and remove duplicates and sort alphabetically from exposure.data

    const temp: React.SetStateAction<any[]> = [];
    exposure?.data?.allocation_by_area.forEach((area: any) => {
      temp.push(area.area);
    });
    exposure?.data?.count_by_area.forEach((area: any) => {
      temp.push(area.area);
    });
    exposure?.data?.current_status.forEach((area: any) => {
      temp.push(area.area);
    });
    exposure?.data?.risk_value.forEach((area: any) => {
      temp.push(area.area);
    });
    exposure?.data?.transactions.forEach((area: any) => {
      temp.push(area.area);
    });
    exposure?.data?.vehicle_count.forEach((area: any) => {
      temp.push(area.area);
    });

    const sortedAreas = temp.sort();

    const uniqueAreas: any[] = [];
    sortedAreas.forEach((area: any) => {
      if (!uniqueAreas.includes(area)) {
        uniqueAreas.push(area);
      }
    });

    const areaList: any[] = [];
    uniqueAreas.forEach((area: any) => {
      if (area !== "") {
        areaList.push({
          name: area,
          value: area,
        });
      } else {
        areaList.push({
          name: "All Areas",
          value: "",
        });
      }
    });

    return areaList;
  }

  function getStatusList() {
    //extract all status values and remove duplicates and sort alphabetically from warrant.data

    const temp: React.SetStateAction<any[]> = [];
    if (currentTab === "warrant") {
      warrant?.data?.vehicle_count_by_warrant_status.forEach((status: any) => {
        temp.push(status.warrant_status);
      });
      warrant?.data?.risk_value.forEach((status: any) => {
        temp.push(status.status);
      });
      warrant?.data?.transactions.forEach((status: any) => {
        temp.push(status.status);
      });
      warrant?.data?.vehicle_licence_by_warrant_status.forEach(
        (status: any) => {
          temp.push(status.status);
        }
      );
      warrant?.data?.warrant_status_unique.forEach((status: any) => {
        temp.push(status.status);
      });
    } else {
      recovery?.data?.vehicle_count_by_recovery_status.forEach(
        (status: any) => {
          temp.push(status.status);
        }
      );
      recovery?.data?.risk_value.forEach((status: any) => {
        temp.push(status.status);
      });
      recovery?.data?.transactions.forEach((status: any) => {
        temp.push(status.status);
      });
      recovery?.data?.vehicle_licence_by_recovery_status.forEach(
        (status: any) => {
          temp.push(status.status);
        }
      );
      recovery?.data?.recovery_status_unique.forEach((status: any) => {
        temp.push(status.status);
      });
    }

    const sortedStatus = temp.sort();

    const uniqueStatus: any[] = [];
    sortedStatus.forEach((status: any) => {
      if (!uniqueStatus.includes(status)) {
        uniqueStatus.push(status);
      }
    });

    const statusList: any[] = [];

    statusList.push({
      name: "All Statuses",
      value: "",
    });

    uniqueStatus.forEach((status: any) => {
      statusList.push({
        name: status,
        value: status,
      });
    });

    return statusList;
  }

  const defaultProps = {
    center: {
      lat: -29.857233,
      lng: 31.021517,
    },
    zoom: 11,
  };

  function enlargeMap() {
    return () => {
      const map = document.getElementById("map");
      const pageContent = document.getElementById("content");

      if (map) {
        map.classList.toggle("enlarged");
      }

      if (pageContent) {
        pageContent.classList.toggle("down");
      }
    };
  }

  const handleDateChange = (date: any) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    // 'rgba(255, 0, 0, 1)',
    "rgba(0, 255, 255, 0)",
  ];

  const onLoad = (heatmap: { set: (arg0: string, arg1: string[]) => void }) => {
    heatmap.set("map", map);
  };

  const onUnmount = (heatmap: { set: (arg0: string, arg1: null) => void }) => {
    heatmap.set("data", null);
  };

  function getDateRange() {
    //return date range if start date and end date are not null. If the are return the past 60 days
    if (startDate !== null && endDate !== null) {
      return (
        String(format(startDate, "dd.MM.yyy")) +
        " - " +
        String(format(endDate, "dd.MM.yyy"))
      );
    } else {
      if (currentTab === "exposure") {
        if (uniqueVehiclesByDate.length !== 0) {
          const today = new Date(
            uniqueVehiclesByDate[uniqueVehiclesByDate.length - 1].pv ?? ""
          );
          const pastDate = new Date(uniqueVehiclesByDate[0].pv ?? "");
          return (
            String(format(pastDate, "dd.MM.yyy")) +
            " - " +
            String(format(today, "dd.MM.yyy"))
          );
        }
      }

      if (currentTab === "warrant" || currentTab === "recovery") {
        if (uniqueVehiclesByWarrantStatus.length !== 0) {
          const today = new Date(
            uniqueVehiclesByWarrantStatus[
              uniqueVehiclesByWarrantStatus.length - 1
            ].pv ?? ""
          );
          const pastDate = new Date(uniqueVehiclesByWarrantStatus[0].pv ?? "");
          return (
            String(format(pastDate, "dd.MM.yyy")) +
            " - " +
            String(format(today, "dd.MM.yyy"))
          );
        }
      }
    }
  }

  function getMonths() {
    if (startDate !== null && endDate !== null) {
      if (String(format(startDate, "MMM")) === String(format(endDate, "MMM"))) {
        return String(format(startDate, "MMM"));
      }
    } else {
      if (currentTab === "exposure") {
        if (uniqueVehiclesByDate.length !== 0) {
          const today = new Date(
            uniqueVehiclesByDate[uniqueVehiclesByDate.length - 1].pv ?? ""
          );
          const pastDate = new Date(uniqueVehiclesByDate[0].pv ?? "");

          if (
            String(format(pastDate, "MMM")) === String(format(today, "MMM"))
          ) {
            return String(format(pastDate, "MMM"));
          } else {
            return (
              String(format(pastDate, "MMM")) +
              " - " +
              String(format(today, "MMM"))
            );
          }
        }
      }

      if (currentTab === "warrant" || currentTab === "recovery") {
        if (uniqueVehiclesByWarrantStatus.length !== 0) {
          const today = new Date(
            uniqueVehiclesByWarrantStatus[
              uniqueVehiclesByWarrantStatus.length - 1
            ].pv ?? ""
          );
          const pastDate = new Date(uniqueVehiclesByWarrantStatus[0].pv ?? "");

          if (
            String(format(pastDate, "MMM")) === String(format(today, "MMM"))
          ) {
            return String(format(pastDate, "MMM"));
          } else {
            return (
              String(format(pastDate, "MMM")) +
              " - " +
              String(format(today, "MMM"))
            );
          }
        }
      }
    }
  }

  const BarColour = ({ fill, x, y, width, height }: any) => {
    return (
      <rect
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
        rx={10}
        ry={10}
        stroke="transparent"
        strokeWidth="5"
      />
    );
  };

  if (!isLoaded) {
    return <Loading />;
  }

  return (
    <div className="background">
      {loading && <Loading />}
      <div id="map" className="map-container">
        <GoogleMap
          onLoad={(map) => setMap(map)}
          mapContainerStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          center={mapCenter}
          zoom={12}
          options={{
            disableDefaultUI: true,
            backgroundColor: "white",
            maxZoom: 10,
          }}
        >
          {map && (
            <HeatmapLayer
              onLoad={onLoad}
              onUnmount={onUnmount}
              data={transactions.map((transaction) => {
                const gps = transaction.gps.split(",");
                return {
                  location: new window.google.maps.LatLng(
                    Number(gps[0]),
                    Number(gps[1])
                  ),
                  weight: 1,
                };
              })}
              options={{
                dissipating: true,
                radius: 40,
                opacity: 0.6,
                maxIntensity: 10,
                // gradient: gradient,
                map: map,
              }}
            />
          )}
          {/* {transactions.map((transaction, index) => {
            const gps = transaction.gps.split(',')
            return (
              <div key={index}>
                <OverlayView
                  position={{
                    lat: Number(gps[0]),
                    lng: Number(gps[1])
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                  <div
                    className="marker"
                    onClick={() => {
                      console.log(transaction)
                    }}
                  />
                </OverlayView>
              </div>
            )
          })} */}
        </GoogleMap>
      </div>

      <TopRow />

      <div id="content" className="content">
        <div className="second-row">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="filter-container"
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
            >
              <img src={filter} className="filter" alt="filter" />
            </div>
            {filterOpen && (
              <div
                style={{
                  zIndex: 100,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <DatePicker
                  selectsRange
                  selected={startDate}
                  onChange={(date) => handleDateChange(date)}
                  startDate={startDate !== null ? startDate : null}
                  endDate={endDate !== null ? endDate : null}
                  customInput={
                    <div className="filter-button">
                      {startDate !== null
                        ? String(format(startDate, "dd.MM.yyy"))
                        : "Start Date"}{" "}
                      -{" "}
                      {endDate !== null
                        ? String(format(endDate, "dd.MM.yyy"))
                        : "End Date"}
                    </div>
                  }
                />
                {currentTab === "exposure" && (
                  <div>
                    <div
                      className="filter-button"
                      onClick={() => setAreasOpen(!areasOpen)}
                    >
                      {area !== "" ? area : "All Areas"}
                      <span>
                        <img
                          className="arrow-down"
                          src={arrowDown}
                          alt="arrow-down"
                        />
                      </span>
                    </div>
                    {areasOpen && (
                      <div className="areas-container">
                        {areas.map((area, index) => {
                          return (
                            <div
                              key={index}
                              className="area"
                              onClick={() => {
                                setArea(area.value);
                                setAreasOpen(false);
                              }}
                            >
                              {area.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                {(currentTab === "warrant" || currentTab === "recovery") && (
                  <div>
                    <div
                      className="filter-button"
                      onClick={() => setAreasOpen(!areasOpen)}
                    >
                      {status !== "" ? status : "All Statuses"}
                      <span>
                        <img
                          className="arrow-down"
                          src={arrowDown}
                          alt="arrow-down"
                        />
                      </span>
                    </div>
                    {areasOpen && (
                      <div className="areas-container">
                        {statuses.map((status, index) => {
                          return (
                            <div
                              key={index}
                              className="area"
                              onClick={() => {
                                setStatus(status.value);
                                setAreasOpen(false);
                              }}
                            >
                              {status.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="tabs">
            <div
              onClick={() => {
                setFilterOpen(false);
                resetAllData();
                setCurrentTab("exposure");
              }}
              className="tab-container"
            >
              <div
                className={
                  currentTab === "exposure" ? "tab-text-active" : "tab-text"
                }
              >
                Exposure
              </div>
            </div>
            <div
              onClick={() => {
                setFilterOpen(false);
                resetAllData();
                setCurrentTab("warrant");
              }}
              className="tab-container"
            >
              <div
                className={
                  currentTab === "warrant" ? "tab-text-active" : "tab-text"
                }
              >
                Warrant
              </div>
            </div>
            <div
              onClick={() => {
                setFilterOpen(false);
                resetAllData();
                setCurrentTab("recovery");
              }}
              className="tab-container"
            >
              <div
                className={
                  currentTab === "recovery" ? "tab-text-active" : "tab-text"
                }
              >
                Recovery
              </div>
            </div>
            <div className="enlarge-container" onClick={enlargeMap()}>
              <img src={enlarge} className="enlarge" alt="enlarge" />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
          }}
        >
          {currentTab === "exposure" && (
            <div className="exposure-container">
              <div className="allocation-by-area-container">
                <div className="table-header-dashboard">
                  % Allocation by Area
                </div>

                <PieChart
                  width={300}
                  height={300}
                  barGap={0}
                  barCategoryGap={0}
                  style={{}}
                >
                  <Pie
                    onClick={(data: any, index: any) => {
                      setArea(data.area);
                    }}
                    data={top10AllocationByArea}
                    dataKey="value"
                    nameKey="area"
                  />
                  <Tooltip
                    separator=""
                    cursor={false}
                    wrapperStyle={{
                      backgroundColor: "transparent",
                    }}
                    contentStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    label={""}
                    trigger="hover"
                    labelStyle={{ opacity: 0 }}
                    // active={tooltipOpen}
                    formatter={(value: any, name: any, props: any, id: any) => {
                      return (
                        <div className="tooltip-container">
                          <Table
                            paging={false}
                            search=""
                            tableName={"tooltip-table-area" + id}
                            className="count-table-tooltip"
                            columns={[
                              { title: "Area", data: "area" },
                              {
                                title: "Arrears",
                                data: "value",
                                render: (data: any) => {
                                  return "R" + data.toLocaleString();
                                },
                              },
                            ]}
                            data={[props.payload]}
                          />
                        </div>
                      );
                    }}
                  />
                </PieChart>
                <Table
                  search=""
                  order={[[2, "desc"]]}
                  paging={false}
                  tableName="allocation-by-area-table"
                  className="count-table-allocation"
                  columns={[
                    { title: "", data: "area" },
                    {
                      title: "",
                      data: "value",
                      render: (data: any) => {
                        return "(R" + data.toLocaleString() + ")";
                      },
                    },
                    {
                      title: "",
                      data: "percentage",
                      render: (data: any) => {
                        return data + "%";
                      },
                    },
                  ]}
                  data={allocationByArea}
                />
              </div>
              <div className="page-content">
                <div className="top-row-container">
                  <div id="risk-value" className="risk-value-container">
                    <AreaChart width={350} height={62} data={riskValueData}>
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop stopColor="#6C8DF9" />
                          <stop
                            offset="1"
                            stopColor="#ffffff"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="uv"
                        stroke="#4A6BDA"
                        dot={false}
                        fill="url(#colorUv)"
                        vectorEffect="non-scaling-stroke"
                      />
                    </AreaChart>
                    <div className="table-footer">
                      <div className="table-footer-text">Risk Value</div>
                      <div className="risk-value-text">{riskValue}</div>
                    </div>
                  </div>
                  <div className="risk-pool-container">
                    <div className="row-container">
                      <div className="table-footer-text">Vehicle Risk Pool</div>
                      <div className="risk-pool-text">{vehicleRiskPool}</div>
                    </div>
                    <div className="row-container">
                      <div className="table-footer-text">High Risk Reads</div>
                      <div className="risk-pool-text">{highRiskReads}</div>
                    </div>
                    <div className="row-container">
                      <div className="table-footer-text">Unique Reads</div>
                      <div className="risk-pool-text">{uniqueReads}</div>
                    </div>
                  </div>
                  <div className="unique-vehicles-container">
                    <div className="row-container">
                      <div className="table-header">
                        Unique Vehicles by Date
                      </div>
                      <div className="date-value-text">{getDateRange()}</div>
                    </div>
                    <BarChart
                      id="unique-vehicles"
                      width={800}
                      height={96}
                      data={uniqueVehiclesByDate}
                    >
                      {/* Render x axis with days of the month in current month */}
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        dataKey={"uv"}
                        style={{ display: "none", width: 0 }}
                      >
                        <Label
                          value={getMonths()}
                          position="insideLeft"
                          angle={-90}
                          style={{
                            textAnchor: "middle",
                            fontFamily: "Poppins",
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#000000",
                            lineHeight: "normal",
                            fontStyle: "normal",
                          }}
                        />
                      </YAxis>
                      <XAxis
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "#000000",
                          lineHeight: "normal",
                          fontStyle: "normal",
                        }}
                        height={20}
                        dataKey="pv"
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(value: any) => {
                          return value.split("-")[2];
                        }}
                        interval={0}
                      />
                      <Bar
                        barSize={3}
                        dataKey="uv"
                        fill="#6C8DF9"
                        radius={[10, 10, 10, 10]}
                      />
                      <Tooltip
                        separator=""
                        cursor={false}
                        wrapperStyle={{
                          backgroundColor: "transparent",
                        }}
                        contentStyle={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        label={""}
                        trigger="hover"
                        labelStyle={{ opacity: 0 }}
                        // cursor={{ stroke: 'red', strokeWidth: 2 }}
                        // active={tooltipOpen}
                        formatter={(value: any, name: any, props: any) => {
                          return (
                            <div className="tooltip-container">
                              <Table
                                paging={false}
                                search=""
                                tableName={"tooltip-table-unique" + name}
                                className="count-table-tooltip"
                                columns={[
                                  { title: "Date", data: "pv" },
                                  {
                                    title: "Unique Vehicles",
                                    data: "uv",
                                  },
                                ]}
                                data={[props.payload]}
                              />
                            </div>
                          );
                        }}
                      />
                    </BarChart>
                  </div>
                </div>
                <div className="bottom-row-container">
                  <div className="table-container">
                    <div className="table-row-top">
                      <div className="table-header">Top 10 Areas</div>
                      <Input
                        // labelStyle={{ marginLeft: '25px' }}
                        isSearch={true}
                        style={{ margin: 0 }}
                        label="Search"
                        id="search-area"
                        name="search"
                        placeholder="Search..."
                        value={searchVal}
                        handleChange={(text) => {
                          setSearchVal(text);
                        }}
                        disabled={false}
                        isError={false}
                        errorText=""
                        // hasArrow={false}
                      />
                    </div>
                    <div
                      id="bar-chart-area"
                      style={{ height: "200px", marginTop: "20px" }}
                    >
                      <BarChart
                        id="top-10-areas"
                        width={
                          document.getElementById("bar-chart-area")?.clientWidth
                        }
                        height={
                          document.getElementById("bar-chart-area")
                            ?.clientHeight
                        }
                        data={top10Areas}
                      >
                        <Bar
                          barSize={25}
                          dataKey="value"
                          fill="#8884d8"
                          shape={<BarColour />}
                          radius={12}
                          background={{
                            fill: "#F6F8FF",
                            radius: 10,
                          }}
                        >
                          {top10Areas.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={barColours[index % 20]}
                            />
                          ))}
                        </Bar>
                        <XAxis
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "11px",
                            fontWeight: "300",
                            color: "#000000",
                            lineHeight: "14px",
                            fontStyle: "normal",
                          }}
                          dataKey="value"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value: any) => {
                            return value;
                          }}
                          interval={0}
                          height={14}
                        />
                        <Tooltip
                          separator=""
                          cursor={false}
                          wrapperStyle={{
                            backgroundColor: "transparent",
                          }}
                          contentStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          label={""}
                          trigger="hover"
                          labelStyle={{ opacity: 0 }}
                          // cursor={{ stroke: 'red', strokeWidth: 2 }}
                          // active={tooltipOpen}
                          formatter={(value: any, name: any, props: any) => {
                            return (
                              <div className="tooltip-container">
                                <Table
                                  paging={false}
                                  search=""
                                  tableName={"tooltip-table-top-10-area" + name}
                                  className="count-table-tooltip"
                                  columns={[
                                    { title: "Area", data: "area" },
                                    {
                                      title: "Arrears",
                                      data: "arrears",
                                      render: (data: any) => {
                                        return "R" + data.toLocaleString();
                                      },
                                    },
                                    {
                                      title: "Count of ID",
                                      data: "value",
                                    },
                                  ]}
                                  data={[props.payload]}
                                />
                              </div>
                            );
                          }}
                        />
                      </BarChart>
                    </div>

                    <Table
                      paging={false}
                      order={[[2, "desc"]]}
                      tableName="count-by-area-table"
                      search={searchVal}
                      className="count-table"
                      columns={[
                        { title: "Area", data: "area" },
                        {
                          title: "Arrears",
                          data: "arrears",
                          render: (data: any) => {
                            return "R" + data.toLocaleString();
                          },
                        },
                        {
                          title: "Count of ID",
                          data: "value",
                        },
                      ]}
                      data={countByArea}
                    />
                    <div className="total-row">
                      <div className="total-text">Total</div>
                      <div className="total-value-mid">
                        R{totalArrears.toLocaleString()}
                      </div>
                      <div className="total-value">{totalValue}</div>
                    </div>
                  </div>
                  <div className="table-container">
                    <div className="table-row-top">
                      <div className="table-header">Top 10 Cameras</div>
                      <Input
                        isSearch={true}
                        style={{ margin: 0 }}
                        label="Search"
                        id="search"
                        name="search"
                        placeholder="Search..."
                        value={searchValCameras}
                        handleChange={(text) => {
                          setSearchValCameras(text);
                        }}
                        disabled={false}
                        isError={false}
                        errorText=""
                      />
                    </div>
                    <div
                      id="bar-chart-camera"
                      style={{ height: "200px", marginTop: "20px" }}
                    >
                      <BarChart
                        id="top-10-cameras"
                        width={
                          document.getElementById("bar-chart-camera")
                            ?.clientWidth
                        }
                        height={
                          document.getElementById("bar-chart-camera")
                            ?.clientHeight
                        }
                        data={top10Cameras}
                      >
                        <Bar
                          barSize={25}
                          dataKey="value"
                          fill="#8884d8"
                          shape={<BarColour />}
                          radius={12}
                          background={{
                            fill: "#F6F8FF",
                            radius: 10,
                          }}
                        >
                          {top10Cameras.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={barColours[index % 20]}
                            />
                          ))}
                        </Bar>
                        <XAxis
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "11px",
                            fontWeight: "300",
                            color: "#000000",
                            lineHeight: "14px",
                            fontStyle: "normal",
                          }}
                          dataKey="value"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value: any) => {
                            return value;
                          }}
                          interval={0}
                          height={14}
                        />
                        <Tooltip
                          separator=""
                          cursor={false}
                          wrapperStyle={{
                            backgroundColor: "transparent",
                          }}
                          contentStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          label={""}
                          trigger="hover"
                          labelStyle={{ opacity: 0 }}
                          // cursor={{ stroke: 'red', strokeWidth: 2 }}
                          // active={tooltipOpen}
                          formatter={(value: any, name: any, props: any) => {
                            return (
                              <div className="tooltip-container">
                                <Table
                                  paging={false}
                                  search=""
                                  tableName={
                                    "tooltip-table-top-10-camera" + name
                                  }
                                  className="count-table-tooltip"
                                  columns={[
                                    { title: "Camera ID", data: "area" },
                                    {
                                      title: "Arrears",
                                      data: "arrears",
                                      render: (data: any) => {
                                        return "R" + data.toLocaleString();
                                      },
                                    },
                                    {
                                      title: "Count of ID",
                                      data: "value",
                                    },
                                  ]}
                                  data={[props.payload]}
                                />
                              </div>
                            );
                          }}
                        />
                      </BarChart>
                    </div>
                    <Table
                      paging={false}
                      order={[[2, "desc"]]}
                      tableName="count-by-camera-table"
                      search={searchValCameras}
                      className="count-table"
                      columns={[
                        { title: "Camera ID", data: "area" },
                        {
                          title: "Arrears",
                          data: "arrears",
                          render: (data: any) => {
                            return "R" + data.toLocaleString();
                          },
                        },
                        {
                          title: "Count of ID",
                          data: "value",
                        },
                      ]}
                      data={countByCamera}
                    />
                    <div className="total-row">
                      <div className="total-text">Total</div>
                      <div className="total-value-mid">
                        R{totalArrearsCameras.toLocaleString()}
                      </div>
                      <div className="total-value">{totalValueCameras}</div>
                    </div>
                  </div>
                  <div className="column-container">
                    <div className="status-container">
                      <div className="table-row-top">
                        <div className="table-header">Current Status</div>
                        <Input
                          isSearch={true}
                          isSmall={true}
                          style={{ margin: 0 }}
                          // label="Search"
                          id="statusSearch"
                          name="search"
                          placeholder=""
                          value={searchValStatus}
                          handleChange={(text) => {
                            setSearchValStatus(text);
                          }}
                          disabled={false}
                          isError={false}
                          errorText=""
                        />
                      </div>
                      <div className="pie-container">
                        <PieChart
                          style={{}}
                          width={170}
                          height={170}
                          barGap={0}
                          barCategoryGap={0}
                        >
                          <Pie
                            data={top10Status}
                            dataKey="value"
                            nameKey="area"
                            onClick={(data: any, index: any) => {
                              setStatus(data.current_status);
                            }}
                          />
                          <Tooltip
                            separator=""
                            cursor={false}
                            wrapperStyle={{
                              backgroundColor: "transparent",
                              zIndex: 100,
                            }}
                            contentStyle={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            offset={-80}
                            label={""}
                            trigger="hover"
                            labelStyle={{ opacity: 0 }}
                            // active={tooltipOpen}
                            formatter={(
                              value: any,
                              area: any,
                              props: any,
                              id: any
                            ) => {
                              return (
                                <div className="tooltip-container">
                                  <Table
                                    paging={false}
                                    search=""
                                    tableName={"tooltip-table" + id}
                                    className="count-table-tooltip"
                                    columns={[
                                      { title: "Area", data: "area" },
                                      {
                                        title: "Status",
                                        data: "current_status",
                                      },
                                      {
                                        title: "Count of ID",
                                        data: "value",
                                      },
                                    ]}
                                    data={[props.payload]}
                                  />
                                </div>
                              );
                            }}
                          />
                        </PieChart>
                      </div>
                      <Table
                        order={[[1, "desc"]]}
                        paging={false}
                        tableName="current-status-table"
                        search={searchValStatus}
                        className="status-count-table"
                        columns={[
                          { title: "Current Status", data: "current_status" },
                          {
                            title: "Count of ID",
                            data: "value",
                          },
                        ]}
                        data={currentStatus}
                      />
                      <div className="total-row">
                        <div className="total-text">Total</div>
                        <div className="total-value">{totalValueStatus}</div>
                      </div>
                    </div>
                    <div className="vehicle-count-container">
                      <div className="table-row-top">
                        <div className="table-header">Vehicle Count</div>
                        <Input
                          isSearch={true}
                          isSmall={true}
                          style={{ margin: 0 }}
                          // label="Search"
                          id="vehicleSearch"
                          name="search"
                          placeholder=""
                          value={searchValVehicle}
                          handleChange={(text) => {
                            setSearchValVehicle(text);
                          }}
                          disabled={false}
                          isError={false}
                          errorText=""
                        />
                      </div>
                      <Table
                        order={[[1, "desc"]]}
                        paging={false}
                        tableName="vehicle-count-table"
                        search={searchValVehicle}
                        className="status-count-table"
                        columns={[
                          { title: "Vehicle License", data: "vehiclelicence" },
                          {
                            title: "Count of ID",
                            data: "value",
                          },
                        ]}
                        data={vehicleCount}
                      />
                      <div className="total-row">
                        <div className="total-text">Total</div>
                        <div className="total-value">{totalValueVehicles}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentTab === "warrant" && (
            <div className="exposure-container">
              <div className="allocation-by-area-container">
                <div className="table-header-dashboard">
                  Vehicle Count by Warrant Status
                </div>
                <PieChart
                  width={300}
                  height={300}
                  barGap={0}
                  barCategoryGap={0}
                  style={{}}
                >
                  <Pie
                    onClick={(data: any, index: any) => {
                      setStatus(data.warrant_status);
                    }}
                    data={top10VehicleCountByWarrantStatus}
                    dataKey="value"
                    nameKey="warrant_status"
                  />
                  <Tooltip
                    separator=""
                    cursor={false}
                    wrapperStyle={{
                      backgroundColor: "transparent",
                      zIndex: 100,
                    }}
                    contentStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    label={""}
                    trigger="hover"
                    labelStyle={{ opacity: 0 }}
                    // active={tooltipOpen}
                    formatter={(value: any, name: any, props: any, id: any) => {
                      return (
                        <div className="tooltip-container">
                          <Table
                            paging={false}
                            search=""
                            tableName={"tooltip-table-area" + id}
                            className="count-table-tooltip"
                            columns={[
                              { title: "Status", data: "warrant_status" },
                              {
                                title: "Count of ID",
                                data: "value",
                              },
                            ]}
                            data={[props.payload]}
                          />
                        </div>
                      );
                    }}
                  />
                </PieChart>
                <Table
                  search=""
                  order={[[2, "desc"]]}
                  paging={false}
                  tableName="allocation-by-area-table"
                  className="count-table-allocation"
                  columns={[
                    { title: "", data: "warrant_status" },
                    {
                      title: "",
                      data: "value",
                      render: (data: any) => {
                        return "(" + data.toLocaleString() + ")";
                      },
                    },
                    {
                      title: "",
                      data: "percentage",
                      render: (data: any) => {
                        return data + "%";
                      },
                    },
                  ]}
                  data={vehicleCountByWarrantStatus}
                />
              </div>
              <div className="page-content">
                <div className="top-row-container">
                  <div id="risk-value" className="risk-value-container">
                    <AreaChart width={350} height={62} data={riskValueData}>
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop stopColor="#6C8DF9" />
                          <stop
                            offset="1"
                            stopColor="#ffffff"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="uv"
                        stroke="#4A6BDA"
                        dot={false}
                        fill="url(#colorUv)"
                        vectorEffect="non-scaling-stroke"
                      />
                    </AreaChart>
                    <div className="table-footer">
                      <div className="table-footer-text">Risk Value</div>
                      <div className="risk-value-text">{riskValue}</div>
                    </div>
                  </div>
                  <div className="risk-pool-container">
                    <div className="row-container">
                      <div className="table-footer-text">Risk Pool</div>
                      <div className="risk-pool-text">{vehicleRiskPool}</div>
                    </div>
                    <div className="row-container">
                      <div className="table-footer-text">High Risk Reads</div>
                      <div className="risk-pool-text">{highRiskReads}</div>
                    </div>
                    <div className="row-container">
                      <div className="table-footer-text">Unique Reads</div>
                      <div className="risk-pool-text">{uniqueReads}</div>
                    </div>
                  </div>
                  <div className="unique-vehicles-container">
                    <div className="row-container">
                      <div className="table-header">
                        Unique Vehicles by Warrant Status
                      </div>
                      <div className="date-value-text">{getDateRange()}</div>
                    </div>

                    <BarChart
                      id="unique-vehicles-warrant"
                      width={800}
                      height={96}
                      data={uniqueVehiclesByWarrantStatus}
                    >
                      {/* Render x axis with days of the month in current month */}
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        dataKey={"uv"}
                        style={{ display: "none" }}
                      >
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontFamily: "Poppins",
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#000000",
                            lineHeight: "normal",
                            fontStyle: "normal",
                          }}
                          value={getMonths()}
                          position="insideLeft"
                          angle={-90}
                        />
                      </YAxis>
                      <XAxis
                        height={20}
                        dataKey="pv"
                        axisLine={false}
                        tickLine={false}
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "#000000",
                          lineHeight: "normal",
                          fontStyle: "normal",
                        }}
                        tickFormatter={(value: any) => {
                          return value.split("-")[2];
                        }}
                        interval={
                          0
                          // // find all unqiue days in current month and divide by 5 to get the interval
                          // uniqueVehiclesByWarrantStatus.length > 5
                          //   ? Math.round(uniqueVehiclesByWarrantStatus.length / 5)
                          //   : 1
                        }
                      />
                      <Bar
                        barSize={3}
                        dataKey="uv"
                        fill="#6C8DF9"
                        radius={[10, 10, 10, 10]}
                      />
                      <Tooltip
                        separator=""
                        cursor={false}
                        wrapperStyle={{
                          backgroundColor: "transparent",
                          zIndex: 1000,
                        }}
                        contentStyle={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        label={""}
                        trigger="hover"
                        labelStyle={{ opacity: 0 }}
                        // cursor={{ stroke: 'red', strokeWidth: 2 }}
                        // active={tooltipOpen}
                        formatter={(value: any, name: any, props: any) => {
                          return (
                            <div className="tooltip-container">
                              <Table
                                paging={false}
                                search=""
                                tableName={"tooltip-table-unique" + name}
                                className="count-table-tooltip"
                                columns={[
                                  { title: "Date", data: "pv" },
                                  {
                                    title: "Unique Vehicles",
                                    data: "uv",
                                  },
                                ]}
                                data={[props.payload]}
                              />
                            </div>
                          );
                        }}
                      />
                    </BarChart>
                  </div>
                </div>
                <div className="bottom-row-container">
                  <div className="table-container">
                    <div className="table-row-top">
                      <div className="table-header">
                        Top 10 Exposure Risk Vehicles by Warrant Status
                      </div>
                      <Input
                        // labelStyle={{ marginLeft: '25px' }}
                        isSearch={true}
                        style={{ margin: 0 }}
                        label="Search"
                        id="search-area"
                        name="search"
                        placeholder="Search..."
                        value={searchVal}
                        handleChange={(text) => {
                          setSearchVal(text);
                        }}
                        disabled={false}
                        isError={false}
                        errorText=""
                        // hasArrow={false}
                      />
                    </div>
                    <div
                      id="bar-chart-vehicles"
                      style={{ height: "200px", marginTop: "20px" }}
                    >
                      <BarChart
                        id="top-10-vehicles"
                        width={
                          document.getElementById("bar-chart-vehicles")
                            ?.clientWidth
                        }
                        height={
                          document.getElementById("bar-chart-vehicles")
                            ?.clientHeight
                        }
                        data={top10ExposureRiskVehicles}
                      >
                        <Bar
                          activeBar={<div></div>}
                          barSize={25}
                          dataKey="value"
                          fill="#8884d8"
                          shape={<BarColour />}
                          radius={12}
                          background={{
                            fill: "#F6F8FF",
                            radius: 10,
                          }}
                        >
                          {top10ExposureRiskVehicles.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={barColours[index % 20]}
                            />
                          ))}
                        </Bar>
                        <XAxis
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "11px",
                            fontWeight: "300",
                            color: "#000000",
                            lineHeight: "14px",
                            fontStyle: "normal",
                          }}
                          dataKey="value"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value: any) => {
                            return "R" + value.toLocaleString();
                          }}
                          interval={0}
                          height={14}
                        />
                        <Tooltip
                          separator=""
                          cursor={false}
                          wrapperStyle={{
                            backgroundColor: "transparent",
                            zIndex: 100,
                          }}
                          contentStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          itemStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          label={""}
                          trigger="hover"
                          labelStyle={{ opacity: 0 }}
                          // cursor={{ stroke: 'red', strokeWidth: 2 }}
                          // active={tooltipOpen}
                          formatter={(value: any, name: any, props: any) => {
                            return (
                              <div className="tooltip-container">
                                <Table
                                  paging={false}
                                  search=""
                                  tableName={"tooltip-table-top-10-area" + name}
                                  className="count-table-tooltip"
                                  columns={[
                                    { title: "Account", data: "account" },
                                    {
                                      title: "Status",
                                      data: "status",
                                    },
                                    {
                                      title: "Exposure",
                                      data: "value",
                                      render: (data: any) => {
                                        return "R" + data.toLocaleString();
                                      },
                                    },
                                  ]}
                                  data={[props.payload]}
                                />
                              </div>
                            );
                          }}
                        />
                      </BarChart>
                    </div>

                    <Table
                      paging={false}
                      order={[[3, "desc"]]}
                      tableName="count-by-area-table"
                      search={searchVal}
                      className="count-table2"
                      columns={[
                        { title: "Vehicle Licence", data: "vehicle_licence" },
                        {
                          title: "Account",
                          data: "account",
                        },
                        {
                          title: "Status",
                          data: "status",
                        },
                        {
                          title: "Exposure",
                          data: "value",
                          render: (data: any) => {
                            return "R" + data.toLocaleString();
                          },
                        },
                      ]}
                      data={exposureRiskVehicles}
                    />
                    <div className="total-row">
                      <div className="total-text">Total</div>
                      <div className="total-value">
                        R{totalValueRiskVehicles.toLocaleString()}
                      </div>
                    </div>
                  </div>

                  <div className="status-container-2">
                    <div className="table-row-top">
                      <div className="table-header">
                        Top 10 Vehicles by Transaction
                      </div>
                      <Input
                        isSearch={true}
                        style={{
                          margin: 0,
                          width: "35px",
                          opacity: 0,
                          pointerEvents: "none",
                        }}
                        id="search"
                        name="search"
                        placeholder=""
                        value={searchValStatus}
                        handleChange={(text) => {}}
                        disabled={false}
                        isError={false}
                        errorText=""
                      />
                    </div>
                    <div className="pie-container">
                      <PieChart
                        style={{}}
                        width={215}
                        height={215}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        <Pie
                          data={top10Transactions}
                          dataKey="value"
                          nameKey="vehicle_licence"
                          onClick={(data: any, index: any) => {
                            setStatus(data.status);
                          }}
                        />
                        <Tooltip
                          separator=""
                          cursor={false}
                          wrapperStyle={{
                            backgroundColor: "transparent",
                            zIndex: 100,
                          }}
                          contentStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          // offset={-80}
                          label={""}
                          trigger="hover"
                          labelStyle={{ opacity: 0 }}
                          // active={tooltipOpen}
                          formatter={(
                            value: any,
                            area: any,
                            props: any,
                            id: any
                          ) => {
                            return (
                              <div className="tooltip-container">
                                <Table
                                  paging={false}
                                  search=""
                                  tableName={"tooltip-table" + id}
                                  className="count-table-tooltip"
                                  columns={[
                                    {
                                      title: "Licence",
                                      data: "vehicle_licence",
                                    },
                                    {
                                      title: "Status",
                                      data: "status",
                                    },
                                    {
                                      title: "Count of ID",
                                      data: "value",
                                    },
                                  ]}
                                  data={[props.payload]}
                                />
                              </div>
                            );
                          }}
                        />
                      </PieChart>
                    </div>
                    <Table
                      order={[[2, "desc"]]}
                      paging={false}
                      tableName="transaction-table"
                      search={searchValStatus}
                      className="vehicle-transaction-table"
                      columns={[
                        { title: "Licence", data: "vehicle_licence" },
                        {
                          title: "Date",
                          data: "date",
                          render: (data: any) => {
                            return String(format(data, "hh:mm:ss dd.MM.yyy"));
                          },
                        },
                        {
                          title: "Count of ID",
                          data: "value",
                        },
                      ]}
                      data={allTransactions}
                    />
                    <div className="total-row">
                      <div className="total-text">Total</div>
                      <div className="total-value">
                        {totalValueTransactions}
                      </div>
                    </div>
                  </div>
                  <div className="column-container">
                    <div className="status-container">
                      <div className="table-row-top">
                        <div className="table-header">
                          Warrant Status: Unique
                        </div>
                        <Input
                          isSearch={true}
                          style={{ margin: 0 }}
                          isSmall={true}
                          id="statusSearch"
                          name="search"
                          placeholder=""
                          value={searchValStatus}
                          handleChange={(text) => {
                            setSearchValStatus(text);
                          }}
                          disabled={false}
                          isError={false}
                          errorText=""
                        />
                      </div>
                      <div className="pie-container">
                        <PieChart
                          style={{}}
                          width={170}
                          height={170}
                          barGap={0}
                          barCategoryGap={0}
                        >
                          <Pie
                            data={top10CurrentWarrantStatus}
                            dataKey="value"
                            nameKey="status"
                            onClick={(data: any, index: any) => {
                              setStatus(data.status);
                            }}
                          />
                          <Tooltip
                            separator=""
                            cursor={false}
                            wrapperStyle={{
                              backgroundColor: "transparent",
                              zIndex: 100,
                            }}
                            contentStyle={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            offset={-80}
                            label={""}
                            trigger="hover"
                            labelStyle={{ opacity: 0 }}
                            // active={tooltipOpen}
                            formatter={(
                              value: any,
                              area: any,
                              props: any,
                              id: any
                            ) => {
                              return (
                                <div className="tooltip-container">
                                  <Table
                                    paging={false}
                                    search=""
                                    tableName={"tooltip-table" + id}
                                    className="count-table-tooltip"
                                    columns={[
                                      {
                                        title: "Status",
                                        data: "status",
                                      },
                                      {
                                        title: "Count of ID",
                                        data: "value",
                                      },
                                    ]}
                                    data={[props.payload]}
                                  />
                                </div>
                              );
                            }}
                          />
                        </PieChart>
                      </div>
                      <Table
                        paging={false}
                        order={[[1, "desc"]]}
                        tableName="current-status-table"
                        search={searchValStatus}
                        className="status-count-table"
                        columns={[
                          { title: "Current Status", data: "status" },
                          {
                            title: "Count of ID",
                            data: "value",
                          },
                        ]}
                        data={currentWarrantStatus}
                      />
                      <div className="total-row">
                        <div className="total-text">Total</div>
                        <div className="total-value">
                          {totalValueWarrantStatus}
                        </div>
                      </div>
                    </div>
                    <div className="vehicle-count-container">
                      <div className="table-row-top">
                        <div className="table-header">
                          Warrant Status: Total
                        </div>
                        <Input
                          isSearch={true}
                          style={{ margin: 0 }}
                          isSmall={true}
                          id="vehicleSearch"
                          name="search"
                          placeholder=""
                          value={searchValVehicle}
                          handleChange={(text) => {
                            setSearchValVehicle(text);
                          }}
                          disabled={false}
                          isError={false}
                          errorText=""
                        />
                      </div>
                      <Table
                        order={[[1, "desc"]]}
                        paging={false}
                        tableName="vehicle-count-table"
                        search={searchValVehicle}
                        className="status-count-table"
                        columns={[
                          { title: "Vehicle Licence", data: "vehiclelicence" },
                          {
                            title: "Count of ID",
                            data: "value",
                          },
                        ]}
                        data={warrantStatusTotal}
                      />
                      <div className="total-row">
                        <div className="total-text">Total</div>
                        <div className="total-value">
                          {totalValueWarrantStatusTotal}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentTab === "recovery" && (
            <div className="exposure-container">
              <div className="allocation-by-area-container">
                <div className="table-header">
                  Top 10 High Exposure Vehicles by Recovered Status
                </div>
                <PieChart
                  width={300}
                  height={300}
                  barGap={0}
                  barCategoryGap={0}
                  style={{}}
                >
                  <Pie
                    onClick={(data: any, index: any) => {
                      setStatus(data.warrant_status);
                    }}
                    data={top10VehicleCountByRecoveredStatus}
                    dataKey="value"
                    nameKey="warrant_status"
                  />
                  <Tooltip
                    separator=""
                    cursor={false}
                    wrapperStyle={{
                      backgroundColor: "transparent",
                      zIndex: 100,
                    }}
                    contentStyle={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    label={""}
                    trigger="hover"
                    labelStyle={{ opacity: 0 }}
                    // active={tooltipOpen}
                    formatter={(value: any, name: any, props: any, id: any) => {
                      return (
                        <div className="tooltip-container">
                          <Table
                            paging={false}
                            search=""
                            tableName={"tooltip-table-area" + id}
                            className="count-table-tooltip"
                            columns={[
                              { title: "Status", data: "warrant_status" },
                              {
                                title: "Count of ID",
                                data: "value",
                              },
                            ]}
                            data={[props.payload]}
                          />
                        </div>
                      );
                    }}
                  />
                </PieChart>
                <Table
                  search=""
                  order={[[2, "desc"]]}
                  paging={false}
                  tableName="allocation-by-area-table"
                  className="count-table-allocation"
                  columns={[
                    { title: "", data: "warrant_status" },
                    {
                      title: "",
                      data: "value",
                      render: (data: any) => {
                        return "(" + data.toLocaleString() + ")";
                      },
                    },
                    {
                      title: "",
                      data: "percentage",
                      render: (data: any) => {
                        return data + "%";
                      },
                    },
                  ]}
                  data={vehicleCountByRecoveredStatus}
                />
              </div>
              <div className="page-content">
                <div className="top-row-container">
                  <div id="risk-value" className="risk-value-container">
                    <AreaChart width={350} height={62} data={riskValueData}>
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop stopColor="#6C8DF9" />
                          <stop
                            offset="1"
                            stopColor="#ffffff"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="uv"
                        stroke="#4A6BDA"
                        dot={false}
                        fill="url(#colorUv)"
                        vectorEffect="non-scaling-stroke"
                      />
                    </AreaChart>
                    <div className="table-footer">
                      <div className="table-footer-text">Risk Value</div>
                      <div className="risk-value-text">{riskValue}</div>
                    </div>
                  </div>
                  <div className="risk-pool-container">
                    <div className="row-container">
                      <div className="table-footer-text">Risk Pool</div>
                      <div className="risk-pool-text">{vehicleRiskPool}</div>
                    </div>
                    <div className="row-container">
                      <div className="table-footer-text">High Risk Reads</div>
                      <div className="risk-pool-text">{highRiskReads}</div>
                    </div>
                    <div className="row-container">
                      <div className="table-footer-text">Unique Reads</div>
                      <div className="risk-pool-text">{uniqueReads}</div>
                    </div>
                  </div>
                  <div className="unique-vehicles-container">
                    <div className="row-container">
                      <div className="table-header">
                        Unique Vehicles by Warrant Status
                      </div>
                      <div className="date-value-text">{getDateRange()}</div>
                    </div>

                    <BarChart
                      id="unique-vehicles-warrant"
                      width={800}
                      height={96}
                      data={uniqueVehiclesByWarrantStatus}
                    >
                      {/* Render x axis with days of the month in current month */}
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        dataKey={"uv"}
                        style={{ display: "none" }}
                      >
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontFamily: "Poppins",
                            fontSize: "10px",
                            fontWeight: "400",
                            color: "#000000",
                            lineHeight: "normal",
                            fontStyle: "normal",
                          }}
                          value={getMonths()}
                          position="insideLeft"
                          angle={-90}
                        />
                      </YAxis>
                      <XAxis
                        height={20}
                        dataKey="pv"
                        axisLine={false}
                        tickLine={false}
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "#000000",
                          lineHeight: "normal",
                          fontStyle: "normal",
                        }}
                        tickFormatter={(value: any) => {
                          return value.split("-")[2];
                        }}
                        interval={
                          0
                          // // find all unqiue days in current month and divide by 5 to get the interval
                          // uniqueVehiclesByWarrantStatus.length > 5
                          //   ? Math.round(uniqueVehiclesByWarrantStatus.length / 5)
                          //   : 1
                        }
                      />
                      <Bar
                        barSize={3}
                        dataKey="uv"
                        fill="#6C8DF9"
                        radius={[10, 10, 10, 10]}
                      />
                      <Tooltip
                        separator=""
                        cursor={false}
                        wrapperStyle={{
                          backgroundColor: "transparent",
                          zIndex: 1000,
                        }}
                        contentStyle={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        label={""}
                        trigger="hover"
                        labelStyle={{ opacity: 0 }}
                        // cursor={{ stroke: 'red', strokeWidth: 2 }}
                        // active={tooltipOpen}
                        formatter={(value: any, name: any, props: any) => {
                          return (
                            <div className="tooltip-container">
                              <Table
                                paging={false}
                                search=""
                                tableName={"tooltip-table-unique" + name}
                                className="count-table-tooltip"
                                columns={[
                                  { title: "Date", data: "pv" },
                                  {
                                    title: "Unique Vehicles",
                                    data: "uv",
                                  },
                                ]}
                                data={[props.payload]}
                              />
                            </div>
                          );
                        }}
                      />
                    </BarChart>
                  </div>
                </div>
                <div className="bottom-row-container">
                  <div className="table-container">
                    <div className="table-row-top">
                      <div className="table-header">Top 10 Transactions</div>
                      <Input
                        isSearch={true}
                        style={{ margin: 0 }}
                        label="Search"
                        id="search-10-transactions"
                        name="search"
                        placeholder="Search..."
                        value={searchVal}
                        handleChange={(text) => {
                          setSearchVal(text);
                        }}
                        disabled={false}
                        isError={false}
                        errorText=""
                        // hasArrow={false}
                      />
                    </div>
                    <div
                      id="bar-chart-vehicles"
                      style={{ height: "200px", marginTop: "20px" }}
                    >
                      <BarChart
                        id="top-10-vehicles"
                        width={
                          document.getElementById("bar-chart-vehicles")
                            ?.clientWidth
                        }
                        height={
                          document.getElementById("bar-chart-vehicles")
                            ?.clientHeight
                        }
                        data={top10ExposureRiskVehicles}
                      >
                        <Bar
                          activeBar={<div></div>}
                          barSize={25}
                          dataKey="value"
                          fill="#8884d8"
                          shape={<BarColour />}
                          radius={12}
                          background={{
                            fill: "#F6F8FF",
                            radius: 10,
                          }}
                        >
                          {top10ExposureRiskVehicles.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={barColours[index % 20]}
                            />
                          ))}
                        </Bar>
                        <XAxis
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "11px",
                            fontWeight: "300",
                            color: "#000000",
                            lineHeight: "14px",
                            fontStyle: "normal",
                          }}
                          dataKey="value"
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value: any) => {
                            return "R" + value.toLocaleString();
                          }}
                          interval={0}
                          height={14}
                        />
                        <Tooltip
                          separator=""
                          cursor={false}
                          wrapperStyle={{
                            backgroundColor: "transparent",
                            zIndex: 100,
                          }}
                          contentStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          itemStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          label={""}
                          trigger="hover"
                          labelStyle={{ opacity: 0 }}
                          // cursor={{ stroke: 'red', strokeWidth: 2 }}
                          // active={tooltipOpen}
                          formatter={(value: any, name: any, props: any) => {
                            return (
                              <div className="tooltip-container">
                                <Table
                                  paging={false}
                                  search=""
                                  tableName={"tooltip-table-top-10-area" + name}
                                  className="count-table-tooltip"
                                  columns={[
                                    { title: "Account", data: "account" },
                                    {
                                      title: "Status",
                                      data: "status",
                                    },
                                    {
                                      title: "Exposure",
                                      data: "value",
                                      render: (data: any) => {
                                        return "R" + data.toLocaleString();
                                      },
                                    },
                                  ]}
                                  data={[props.payload]}
                                />
                              </div>
                            );
                          }}
                        />
                      </BarChart>
                    </div>
                    {/* <div style={{ height: '216px' }}>
                      <ResponsiveBar
                        tooltip={({ id, value, color, data }: any) => (
                          <strong>
                            <div className="tooltip-container">
                              <Table
                                paging={false}
                                search=""
                                tableName={'tooltip-table' + id}
                                className="count-table"
                                columns={[
                                  { title: 'Vehicle Licence', data: 'vehicle_licence' },
                                  {
                                    title: 'Account',
                                    data: 'account'
                                  },
                                  {
                                    title: 'Status',
                                    data: 'status'
                                  },
                                  {
                                    title: 'Exposure',
                                    data: 'value',
                                    render: (data: any) => {
                                      return 'R' + data.toLocaleString()
                                    }
                                  }
                                ]}
                                data={[data]}
                              />
                            </div>
                          </strong>
                        )}
                        data={top10ExposureRiskVehicles}
                        keys={['value']}
                        indexBy={'id'}
                        colorBy="indexValue"
                        margin={{ top: 20, right: 0, bottom: 10, left: 0 }}
                        colors={barColours}
                        padding={0.5}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        role="application"
                        enableGridX={false}
                        enableGridY={false}
                        borderRadius={10}
                        enableLabel={false}
                      />
                    </div> */}

                    <Table
                      paging={false}
                      order={
                        searchVal === ""
                          ? [[3, "desc"]]
                          : [
                              [3, "desc"],
                              [0, "asc"],
                            ]
                      }
                      tableName="count-by-area-table"
                      search={searchVal}
                      className="count-table2"
                      columns={[
                        { title: "Vehicle Licence", data: "vehicle_licence" },
                        {
                          title: "Account",
                          data: "account",
                        },
                        {
                          title: "Status",
                          data: "status",
                        },
                        {
                          title: "Exposure",
                          data: "value",
                          render: (data: any) => {
                            return "R" + data.toLocaleString();
                          },
                        },
                      ]}
                      data={exposureRiskVehicles}
                    />
                    <div className="total-row">
                      <div className="total-text">Total</div>
                      <div className="total-value">
                        R{totalValueRiskVehicles.toLocaleString()}
                      </div>
                    </div>
                  </div>

                  <div className="status-container-2">
                    <div className="table-row-top">
                      <div className="table-header">
                        Top 10 Vehicles by Transaction
                      </div>
                      <Input
                        isSearch={true}
                        style={{
                          margin: 0,
                          width: "35px",
                          opacity: 0,
                          pointerEvents: "none",
                        }}
                        // label="Search"
                        id="search"
                        name="search"
                        placeholder=""
                        value={searchValStatus}
                        handleChange={(text) => {}}
                        disabled={false}
                        isError={false}
                        errorText=""
                      />
                    </div>
                    <div className="pie-container">
                      <PieChart
                        style={{}}
                        width={215}
                        height={215}
                        barGap={0}
                        barCategoryGap={0}
                      >
                        <Pie
                          data={top10Transactions}
                          dataKey="value"
                          nameKey="vehicle_licence"
                          onClick={(data: any, index: any) => {
                            setStatus(data.status);
                          }}
                        />
                        <Tooltip
                          separator=""
                          cursor={false}
                          wrapperStyle={{
                            backgroundColor: "transparent",
                            zIndex: 100,
                          }}
                          contentStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          // offset={-80}
                          label={""}
                          trigger="hover"
                          labelStyle={{ opacity: 0 }}
                          // active={tooltipOpen}
                          formatter={(
                            value: any,
                            area: any,
                            props: any,
                            id: any
                          ) => {
                            return (
                              <div className="tooltip-container">
                                <Table
                                  paging={false}
                                  search=""
                                  tableName={"tooltip-table" + id}
                                  className="count-table-tooltip"
                                  columns={[
                                    {
                                      title: "Licence",
                                      data: "vehicle_licence",
                                    },
                                    {
                                      title: "Status",
                                      data: "status",
                                    },
                                    {
                                      title: "Count of ID",
                                      data: "value",
                                    },
                                  ]}
                                  data={[props.payload]}
                                />
                              </div>
                            );
                          }}
                        />
                      </PieChart>
                    </div>
                    <Table
                      order={[[2, "desc"]]}
                      paging={false}
                      tableName="transaction-table"
                      search={searchValStatus}
                      className="vehicle-transaction-table"
                      columns={[
                        { title: "Licence", data: "vehicle_licence" },
                        {
                          title: "Date",
                          data: "date",
                          render: (data: any) => {
                            return String(format(data, "hh:mm:ss dd.MM.yyy"));
                          },
                        },
                        {
                          title: "Count of ID",
                          data: "value",
                        },
                      ]}
                      data={allTransactions}
                    />
                    <div className="total-row">
                      <div className="total-text">Total</div>
                      <div className="total-value">
                        {totalValueTransactions}
                      </div>
                    </div>
                  </div>
                  <div className="column-container">
                    <div className="status-container">
                      <div className="table-row-top">
                        <div className="table-header">
                          Recovered Status: Unique
                        </div>
                        <Input
                          isSearch={true}
                          isSmall={true}
                          style={{ margin: 0 }}
                          id="statusSearch"
                          name="search"
                          placeholder=""
                          value={searchValStatus}
                          handleChange={(text) => {
                            setSearchValStatus(text);
                          }}
                          disabled={false}
                          isError={false}
                          errorText=""
                        />
                      </div>
                      <Table
                        paging={false}
                        order={[[1, "desc"]]}
                        tableName="current-status-table-2"
                        search={searchValStatus}
                        className="status-count-table"
                        columns={[
                          { title: "Current Status", data: "status" },
                          {
                            title: "Count of ID",
                            data: "value",
                          },
                        ]}
                        data={currentWarrantStatus}
                      />
                      <div className="total-row">
                        <div className="total-text">Total</div>
                        <div className="total-value">
                          {totalValueWarrantStatus}
                        </div>
                      </div>
                    </div>
                    <div className="vehicle-count-container">
                      <div className="table-row-top">
                        <div className="table-header">Recovered Status</div>
                        <Input
                          isSearch={true}
                          isSmall={true}
                          style={{ margin: 0 }}
                          id="vehicleSearch"
                          name="search"
                          placeholder=""
                          value={searchValVehicle}
                          handleChange={(text) => {
                            setSearchValVehicle(text);
                          }}
                          disabled={false}
                          isError={false}
                          errorText=""
                        />
                      </div>
                      <div className="pie-container">
                        <PieChart
                          style={{}}
                          width={170}
                          height={170}
                          barGap={0}
                          barCategoryGap={0}
                        >
                          <Pie
                            data={top10RecoveryStatus}
                            dataKey="value"
                            nameKey="status"
                            onClick={(data: any, index: any) => {
                              setStatus(data.status);
                            }}
                          />
                          <Tooltip
                            separator=""
                            cursor={false}
                            wrapperStyle={{
                              backgroundColor: "transparent",
                              zIndex: 100,
                            }}
                            contentStyle={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            offset={-80}
                            label={""}
                            trigger="hover"
                            labelStyle={{ opacity: 0 }}
                            // active={tooltipOpen}
                            formatter={(
                              value: any,
                              area: any,
                              props: any,
                              id: any
                            ) => {
                              return (
                                <div className="tooltip-container">
                                  <Table
                                    paging={false}
                                    search=""
                                    tableName={"tooltip-table" + id}
                                    className="count-table-tooltip"
                                    columns={[
                                      {
                                        title: "Vehicle Licence",
                                        data: "vehicle_licence",
                                      },
                                      {
                                        title: "Count of ID",
                                        data: "value",
                                      },
                                    ]}
                                    data={[props.payload]}
                                  />
                                </div>
                              );
                            }}
                          />
                        </PieChart>
                      </div>
                      <Table
                        order={[[1, "desc"]]}
                        paging={false}
                        tableName="vehicle-count-table-2"
                        search={searchValVehicle}
                        className="status-count-table"
                        columns={[
                          { title: "Vehicle Licence", data: "vehicle_licence" },
                          {
                            title: "Count of ID",
                            data: "value",
                          },
                        ]}
                        data={warrantStatusTotal}
                      />
                      <div className="total-row">
                        <div className="total-text">Total</div>
                        <div className="total-value">
                          {totalValueWarrantStatusTotal}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
