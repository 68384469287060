// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.its-ui-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.its-ui-checkbox {
  width: 22px;
  height: 22px;
}

.its-ui-checkbox-label {
  margin-bottom: 0 !important;

  height: 20px;
  text-align: center;
  margin-left: 10px;
  color: #000000;
  font-size: 13px;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  line-height: normal;
}

.its-ui-checkbox-label-checked {
  margin-bottom: 0 !important;
  height: 20px;
  text-align: center;
  /* vertical-align: middle; */
  margin-left: 10px;
  color: #000000;
  font-size: 13px;
  font-style: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  line-height: normal;
}

.background-image {
  position: absolute;
  /* width: 3761.75px;
  height: 2347px; */
  flex-shrink: 0;
  z-index: 0;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,2BAA2B;;EAE3B,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,kCAAkC;EAClC,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;EAC5B,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,kCAAkC;EAClC,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB;mBACiB;EACjB,cAAc;EACd,UAAU;EACV,UAAU;AACZ","sourcesContent":[".its-ui-checkbox-container {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.its-ui-checkbox {\n  width: 22px;\n  height: 22px;\n}\n\n.its-ui-checkbox-label {\n  margin-bottom: 0 !important;\n\n  height: 20px;\n  text-align: center;\n  margin-left: 10px;\n  color: #000000;\n  font-size: 13px;\n  font-style: normal;\n  font-family: 'Poppins', sans-serif;\n  font-weight: 300;\n  line-height: normal;\n}\n\n.its-ui-checkbox-label-checked {\n  margin-bottom: 0 !important;\n  height: 20px;\n  text-align: center;\n  /* vertical-align: middle; */\n  margin-left: 10px;\n  color: #000000;\n  font-size: 13px;\n  font-style: normal;\n  font-family: 'Poppins', sans-serif;\n  font-weight: 300;\n  line-height: normal;\n}\n\n.background-image {\n  position: absolute;\n  /* width: 3761.75px;\n  height: 2347px; */\n  flex-shrink: 0;\n  z-index: 0;\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
