import React, { CSSProperties } from 'react'
import './LoginInput.css'
import PropTypes from 'prop-types'

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.element,
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  labelStyle: PropTypes.element,
}

interface defaultProps {
  isPassword?: boolean
  handlePassword?: () => void
  passwordImage?: string
  hasArrow?: boolean
  arrowImg?: string
  id: string
  arrowID?: string
  label?: string
  placeholder?: string
  value?: string
  defaultValue?: string
  handleChange: (val: string) => void
  handleClick?: () => void
  style?: CSSProperties
  inputStyle?: CSSProperties
  disabled?: boolean
  name: string
  type?: string
  step?: number
  handleBlur?: (val: string) => void
  isError?: boolean
  errorText?: string
  labelStyle?: CSSProperties
  hasChips?: boolean
  chipsId?: string
  min?: number
  max?: number
  inputClassName?: string
}

function Input({
  isPassword = false,
  handlePassword,
  passwordImage,
  label,
  id,
  arrowID,
  placeholder,
  value,
  defaultValue,
  handleChange,
  handleClick,
  disabled,
  name,
  style,
  inputStyle = { width: '100%', backgroundColor: 'transparent' },
  type = 'text',
  step,
  hasArrow = false,
  arrowImg,
  handleBlur = () => null,
  isError = false,
  errorText,
  labelStyle = {},
  hasChips = false,
  chipsId,
  min,
  max,
  inputClassName = 'its-ui-input-disabled',
  ...props
}: defaultProps) {
  return (
    <div
      onClick={handleClick}
      className={
        hasArrow
          ? 'its-ui-input-dropdown-control'
          : disabled
          ? value === ''
            ? 'its-ui-input-control'
            : 'its-ui-input-control disabled'
          : 'its-ui-input-control'
      }
      style={
        {...style, overflowY: hasChips ? 'scroll' : 'visible'}
      }>
       <div
          style={{
          display: hasChips ? 'flex' : 'none',
          }}
            className='chips'
          id={chipsId}
            ></div>
      <div className="its-ui-input-container" >
        <input
          autoComplete='new-password'
          className={
            disabled
              ? hasArrow
                ? 'its-ui-dropdown-input-disabled'
                : inputClassName
              : ''
          }
          style={inputStyle}
          // disabled={hasArrow && disabled}
          id={id}
          type={type}
          name={name}
          placeholder={''}
          onBlur={(e) => handleBlur(e.target.value)}
          required
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => handleChange(e.target.value)}
          step={step}
          min={min}
          max={max}
          {...props}
        />
        <label style={labelStyle}>{label}</label>

        {isPassword && (
          <a
            style={{
              cursor: 'pointer'
            }}
            onClick={handlePassword}>
            <img src={passwordImage} />
          </a>
        )}
        
        {hasArrow && (
          <a
            style={{
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              marginBottom: '2px'
            }}>
            <img id={arrowID} src="/media/svg/files/arrowdown.svg" />
          </a>
        )}
        
      </div>
      {isError && (
        <div className="its-ui-error-container">
          <div className="its-ui-error-text">{errorText}</div>
        </div>
      )}
    </div>
  )
}

export default Input
